import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { UIColumn, UIRow } from '../../components/layout';
import { NavButton } from '../../components/nav-button';
import { Spinner } from '../../components/spinner';
import { StyledText } from '../../components/styled-text';
import { Page } from '../../layout/page.';
import { Collection } from '../../model/collection';
import DataModel, { CollectionResultSet } from '../../model/data-model';
import { IDable } from '../../model/interfaces';
import { NamedColor, Theme } from '../../model/theme';
import { tt } from '../../model/translator';

type ThemeMetaData = {
  name: string;
  key: string;
  description: Text;
  color: NamedColor;
  letterImageName?: string;
};

const ThemeEntryWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 80%;
  margin-bottom: 30px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 6px;
`;

function themeKey(s: string) {
  return s.replaceAll(' ', '-').toLowerCase();
}

type ThemeEntryProps = {
  collection: Collection;
  onclick: (collection: Collection) => void;
};
const ThemeEntry = observer((props: ThemeEntryProps) => {
  const metaData = props.collection.theme_name.split('|');
  return (
    <UIColumn align={'center'} style={{ width: '100%' }}>
      <ThemeEntryWrapper>
        <UIColumn align={'flex-start'} style={{ flex: 1 }}>
          <ButtonContainer>
            <StyledText
              styleName={'book-title'}
              overrides={{ relativeSize: 5, spaceAfter: 0, rightIndent: 12 }}
            >
              {metaData[1]}
            </StyledText>
            <Link
              to={`/themes/${themeKey(metaData[1])}`}
              style={{ textDecoration: 'none' }}
            >
              <NavButton
                text={'View'}
                title={tt('view-this-collection')}
                direction={'forward'}
                onClick={() => props.onclick(props.collection)}
              />
            </Link>
          </ButtonContainer>
          <StyledText styleName={'Text2'}>{metaData[2]}</StyledText>
        </UIColumn>
        <ButtonContainer></ButtonContainer>
      </ThemeEntryWrapper>
    </UIColumn>
  );
});

type ThemesPageState = {
  collectionsAreLoading: boolean;
  collections: Collection[];
};
@observer
export class ThemesPage extends React.Component<{}, ThemesPageState> {
  constructor(props: {}) {
    super(props);
    this.state = { collections: [], collectionsAreLoading: false };
  }

  collectionsHaveLoaded(collectionsMap: Map<number, Collection>) {
    const collectionsArray = Array.from(collectionsMap.values());
    this.setState({
      collections: collectionsArray,
      collectionsAreLoading: false,
    });
  }

  componentDidMount() {
    this.setState({ collectionsAreLoading: true });
    DataModel.sharedInstance().getCachedCollections(
      (collectionsMap: Map<number, Collection>) =>
        this.collectionsHaveLoaded(collectionsMap)
    );
  }

  showCollection(collection: Collection) {}

  renderCollections() {
    return (
      <>
        {this.state.collections.map((collection: Collection) => {
          return (
            <ThemeEntry
              key={`theme-collection-${collection.id}`}
              collection={collection}
              onclick={(collection: Collection) =>
                this.showCollection(collection)
              }
            />
          );
        })}
      </>
    );
  }

  getContent() {
    return (
      <UIColumn padding={'20px 0'}>
        {this.state.collectionsAreLoading ? (
          <>
            <Spinner sizeInPixels={128} />
          </>
        ) : (
          this.renderCollections()
        )}
      </UIColumn>
    );
  }

  render() {
    const headerContent = (
      <img
        src={'/images/te_takarangi_white.png'}
        alt={'Te Takarangi Banner'}
        style={{ height: '100px' }}
      />
    );
    return (
      <Page
        pageSectionIndex={2}
        headerSpec={{
          customContent: headerContent,
          backgroundColor: 'accent',
          letterImageLetter: 't',
        }}
        content={this.getContent()}
        footerBackgroundColor={'accent'}
        footerTextColor={'white'}
      />
    );
  }
}
