import { DateTime } from 'luxon';
import { ALStatus } from './al-status';
import { Coreuuidv4 } from '../lib/utility';
import DataModel from './data-model';

type RequestCallback = (response: RequestResponse) => void;
type RequestMethod = 'GET' | 'POST';
type RequestDataType = 'json' | 'text';

/*
———————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
 RequestResponse
———————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
*/

export class RequestResponse {
  identifier: string | undefined;
  startTime: DateTime | undefined;
  endTime: DateTime | undefined;
  status: ALStatus | undefined;
  payload: any;
  requestIdentifier: string | undefined;

  constructor() {
    this.identifier = Coreuuidv4();
    this.startTime = undefined;
    this.endTime = undefined;
    this.status = undefined;
    this.payload = undefined;
    this.requestIdentifier = undefined;
  }

  payloadObjectWithKey(key: string) {
    if (this.payload) {
      if (this.payload.hasOwnProperty(key)) {
        return this.payload[key];
      }
    }
    return undefined;
  }
}

/*
———————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
 APIRequest
———————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
*/

export class APIRequest {
  static _numActiveRequests: number = 0;
  static _defaultServerAddress: string;
  identifier: string;
  action: string;
  method: RequestMethod;
  dataType: RequestDataType;
  contentType: string;
  baseURL: string;
  sentData: any;
  callback: RequestCallback;
  jsonifyData: boolean;
  response: RequestResponse | undefined;
  suppressErrors: boolean;
  private _isInProgress: boolean;
  private _hasCompleted: boolean;

  constructor(action: string, data: any, callback: RequestCallback) {
    APIRequest._defaultServerAddress = DataModel.serverAddress;
    this.identifier = Coreuuidv4();
    this.action = action;
    this.method = 'POST';
    this.dataType = 'json';
    this.contentType = 'application/json; charset=utf-8';
    this.baseURL = 'public/api/';
    this.sentData = data;
    this.callback = callback;
    this.jsonifyData = true;
    this.response = undefined;
    this._hasCompleted = false;
    this._isInProgress = false;
    this.suppressErrors = false;
  }

  isInProgress() {
    return this._isInProgress;
  }

  hasCompleted() {
    return this._hasCompleted;
  }

  getErrorDescriptionForCode(code: number) {
    if (code === 419)
      return 'Session expired - please try reloading page or log out then in again';
    if (code === 500)
      return 'Internal server error - please try reloading page or log out then in again';
    return 'General error';
  }

  private getRequestString() {
    return `${APIRequest._defaultServerAddress}/${this.baseURL}${this.action}`;
  }

  completeRequest() {
    APIRequest._numActiveRequests--;
    this._hasCompleted = true;
    this._isInProgress = false;
  }

  send() {
    this.sentData.requestToken = 'token';
    this.sentData._identifier = this.identifier;
    APIRequest._numActiveRequests++;

    fetch(this.getRequestString(), {
      method: this.method,
      body: JSON.stringify(this.sentData),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    })
      .then((res) => {
        return res.json();
      })
      .then(
        (resultObject) => {
          this.completeRequest();
          const response = new RequestResponse();
          response.status = new ALStatus(resultObject.status);
          response.payload = resultObject.payload;
          this.callback(response);
        },
        (error) => {
          this.completeRequest();
          const response = new RequestResponse();
          response.requestIdentifier = this.identifier;
          response.status = new ALStatus({
            code: error.status,
            message: error.statusText,
            type: 'error',
            extraInfo: `Action: ${this.action}`,
          });
          this.callback(response);
        }
      );
  }
}
