import { action, makeObservable, observable } from 'mobx';
import { Book } from './book';
import { Collection } from './collection';
import { APIRequest, RequestResponse } from './request';
import { Resource } from './resource';

export type Language = 'english' | 'maori';
export type ResultSet = {
  books: Book[];
  booksMap: Map<number, Book>;
  pageIndex: number;
  numberOfBooks: number;
  numberOfPages: number;
  errorStatus: number;
};

export type CollectionResultSet = {
  collections: Collection[];
  collectionsMap: Map<number, Collection>;
  numberOfCollections: number;
  errorStatus: number;
};

class DataModel {
  private static instance: DataModel;
  public static serverAddress: string = 'https://te-takarangi.applab-deploy.nz';
  public static apiPrefix: string = '/public/api/';
  public static mediaPrefix: string = '/media';

  private _cachesAreLoaded: boolean;
  private _cached_book_map: Map<number, Book>;
  private _cached_book_array: Book[];
  private _cached_collection_map: Map<number, Collection>;
  private _cached_resources_array: Resource[];

  @observable currentSectionIndex: number;
  @observable language: Language;
  @observable contentIsLoadingMessage: string | undefined;

  private constructor() {
    this.language = 'english';
    this.currentSectionIndex = 0;
    this._cachesAreLoaded = false;
    this._cached_book_map = new Map<number, Book>();
    this._cached_book_array = [];

    this._cached_collection_map = new Map<number, Collection>();
    this.refreshCaches();
    this._cached_resources_array = [];
    makeObservable(this);
  }

  /*
    ———————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
    Shared instance      
    ———————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
  */

  static sharedInstance(): DataModel {
    if (!DataModel.instance) {
      DataModel.instance = new DataModel();
    }
    return DataModel.instance;
  }

  /*
    ———————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
    Cached entities      
    ———————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
  */

  public getResources(): Resource[] {
    const r1 = new Resource({});
    r1.image_id = 1;
    r1.title_eng =
      'Read our words: An anti-racist reading list for New Zealanders';
    r1.description_eng = 'By Jacinta Ruru, Angela Wanhalla & Jeanette Wikaira';
    r1.linkURL =
      'https://thespinoff.co.nz/atea/15-06-2020/read-our-words-an-anti-racist-reading-list-for-new-zealanders';
    const r2 = new Resource({});
    r2.image_id = 2;
    r2.title_eng =
      'TE TAKARANGI: The significance of curating a sample list of Māori-authored non-fiction books';
    r2.description_eng = 'Jacinta Ruru, Angela Wanhalla & Jeanette Wikaira';
    r2.linkURL =
      'http://www.journal.mai.ac.nz/sites/default/files/MAI_Jrnl_2020_V9_2_Ruru_FINAL.pdf';

    return [r1, r2];
  }

  public getBooksForPage(nonZeroPageIndex: number, pageSize: number = 20) {
    const startIndex =
      Math.min(this._cached_book_map.size, nonZeroPageIndex - 1) * pageSize;
    const endIndex = Math.min(
      this._cached_book_map.size,
      startIndex + pageSize
    );

    const booksInPage = [];
    for (let index = startIndex; index < endIndex; index++) {
      booksInPage.push(this._cached_book_array[index]);
    }
    return booksInPage;
  }

  public getCachedBookWithID(bookID: number): Book | undefined {
    return this._cached_book_map.get(bookID) as Book;
  }

  public getCachedCBooks() {
    return this._cached_book_map;
  }

  public getCachedCollectionWithID(
    collectionID: number
  ): Collection | undefined {
    if (this._cachesAreLoaded) {
      return this._cached_collection_map.get(collectionID) as Collection;
    }
  }

  public getCollectionWithID(
    collectionID: number,
    callback?: (collection: Collection | undefined) => void
  ): Collection | undefined {
    if (this._cachesAreLoaded) {
      return this._cached_collection_map.get(collectionID) as Collection;
    }
    this.refreshCaches(() => {
      if (callback && this._cached_collection_map) {
        callback(this._cached_collection_map.get(collectionID));
      }
    });
  }

  public getCachedCollections(
    callback: (collections: Map<number, Collection>) => void
  ) {
    if (this._cachesAreLoaded) {
      callback(this._cached_collection_map);
    } else {
      this.refreshCaches(() => {
        callback(this._cached_collection_map);
      });
    }
  }

  // Store a complete copy of all books etc in the app.
  public refreshCaches(callback?: () => void) {
    this.contentIsLoadingMessage = 'Loading';
    this._cached_book_map = new Map<number, Book>();
    this._cached_collection_map = new Map<number, Collection>();
    this.loadCollections((resultSet: CollectionResultSet) => {
      this._cached_collection_map = resultSet.collectionsMap;
      this.loadAllBooks((resultSet: ResultSet) => {
        this._cached_book_map = resultSet.booksMap;
        this._cached_book_array = Array.from(resultSet.booksMap.values()) ?? [];
        this._cachesAreLoaded = true;
        this.contentIsLoadingMessage = undefined;
        if (callback) {
          callback();
        }
      });
    });
  }

  /*
    ———————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
    URLs for resources      
    ———————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
  */

  mediaURL(id: number) {
    // /media/{id}/original/{format?}
    return `${DataModel.serverAddress}${DataModel.mediaPrefix}/${id}`;
  }

  /*
    ———————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
    Site navigation      
    ———————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
  */

  @action setSectionIndex(newIndex: number) {
    this.currentSectionIndex = newIndex;
  }

  @action setLanguage(newLanguage: Language) {
    this.language = newLanguage;
  }

  /*
    ———————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
    Data loading - books      
    ———————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
  */

  private mapFromBooksArray(books: Book[]) {
    const indexedBooks = new Map<number, Book>();
    for (let i = 0; i < books.length; i++) {
      const book = books[i];
      indexedBooks.set(book.id, book);
    }
    return indexedBooks;
  }

  getBooksFromRequest(response: RequestResponse): ResultSet {
    const paginationInfo = response.payloadObjectWithKey('data');
    const bookData = paginationInfo.data;
    const books = bookData.map((objectData: any) => new Book(objectData));
    const booksIndexedById = this.mapFromBooksArray(books);
    return {
      books: books,
      booksMap: booksIndexedById,
      pageIndex: paginationInfo.current_page,
      numberOfBooks: paginationInfo.total,
      numberOfPages: paginationInfo.last_page,
      errorStatus: 0,
    };
  }

  performBookSearch(
    searchTerm: string,
    pageSize: number,
    newPageIndex: number,
    dataHasLoaded: (resultSet: ResultSet) => void
  ) {
    const request = new APIRequest(
      'findbooks',
      {
        searchTerm: searchTerm,
        page: newPageIndex,
        pageSize: pageSize,
      },
      (response) => {
        const resultSet = this.getBooksFromRequest(response);
        dataHasLoaded(resultSet);
      }
    );
    request.send();
  }

  loadAllBooks(dataHasLoaded: (resultSet: ResultSet) => void) {
    const request = new APIRequest(
      'allbooks',
      { pageSize: 1000 },
      (response) => {
        const resultSet = this.getBooksFromRequest(response);
        dataHasLoaded(resultSet);
      }
    );
    request.send();
  }

  /*
    ———————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
    Data loading - Collections      
    ———————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
  */

  private mapFromCollectionsArray(collections: Collection[]) {
    const indexedBooks = new Map<number, Collection>();
    for (let i = 0; i < collections.length; i++) {
      const collection = collections[i];
      indexedBooks.set(collection.id, collection);
    }
    return indexedBooks;
  }

  getCollectionsFromRequest(response: RequestResponse): CollectionResultSet {
    const collectionObjects = response.payloadObjectWithKey('collections');

    const collections = collectionObjects.map((objectData: any) => {
      const collection = new Collection(objectData);
      collection.loadFullDataFromResponse(objectData);
      return collection;
    });
    const collectionsIndexedById = this.mapFromCollectionsArray(collections);
    return {
      collections: collections,
      collectionsMap: collectionsIndexedById,
      numberOfCollections: collections.length,
      errorStatus: 0,
    };
  }

  loadCollections(dataHasLoaded: (resultSet: CollectionResultSet) => void) {
    const request = new APIRequest(
      'allcollections',
      { pageSize: 1000 },
      (response) => {
        const resultSet = this.getCollectionsFromRequest(response);
        dataHasLoaded(resultSet);
      }
    );
    request.send();
  }
}

// const myClassInstance = DataModel.Instance;
export default DataModel;
