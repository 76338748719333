import { observer } from 'mobx-react';
import React from 'react';
import { Breakpoint } from 'react-socks';
import styled, { ThemeConsumer } from 'styled-components';
import { UIColumn, UIRow } from '../components/layout';
import { StyledText } from '../components/styled-text';
import { Page } from '../layout/page.';
import { kUShortIAnimationDuration } from '../lib/constants';
import DataModel from '../model/data-model';
import { Resource } from '../model/resource';
import { NamedColor, Theme } from '../model/theme';

const ResourceCell = styled.a<{
  namedBgColor: NamedColor;
  rowOrColumn: 'row' | 'column';
}>`
  display: flex;
  flex-direction: ${(props) => props.rowOrColumn};
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  flex: 1;
  max-width: 90%;
  width: 90%;
  background-color: ${(props) => Theme.color(props.namedBgColor)};
  padding: 20px;
  transition: all ${kUShortIAnimationDuration}s linear;
  &:hover {
    transform: scale(1.02);
    filter: brightness(0.9);
  }
  margin-bottom: 12px;
`;

const ResourceImage = styled.img`
  padding: 6px 20px 12px 0;
  border-radius: 9px;
`;

@observer
export class ResourcesPage extends React.Component {
  renderResourceInfo(resource: Resource, isCompact: boolean) {
    return (
      <UIColumn align={'flex-start'} padding={'4px'}>
        <StyledText
          styleName={'Title3'}
          overrides={{ textColor: 'white', spaceAfter: 12 }}
        >
          {resource.languageAwareTitle()}
        </StyledText>
        <StyledText styleName={'Text2'} overrides={{ textColor: 'white' }}>
          {resource.languageAwareDescription()}
        </StyledText>
      </UIColumn>
    );
  }

  renderResource(resource: Resource, index: number, isCompact: boolean) {
    const bgColorName: NamedColor =
      index % 2 === 0
        ? 'theme-nga-toka-tumoana-cell1'
        : 'theme-nga-toka-tumoana-cell2';
    const imageWidth = isCompact ? '70%' : '320px';
    return (
      <ResourceCell
        href={resource.linkURL}
        target={'_blank'}
        rel='noreferrer'
        key={`resource-link-${resource.id}`}
        namedBgColor={bgColorName}
        rowOrColumn={isCompact ? 'column' : 'row'}
      >
        <ResourceImage
          src={resource.temp_imageURL()}
          style={{ width: imageWidth }}
          alt={''}
        />
        {this.renderResourceInfo(resource, isCompact)}
      </ResourceCell>
    );
  }

  getCompactContent(resources: Resource[]) {
    return (
      <UIColumn align={'center'}>
        {resources.map((resource: Resource, index: number) => {
          return this.renderResource(resource, index, true);
        })}
      </UIColumn>
    );
  }

  getStandardContent(resources: Resource[]) {
    return (
      <UIColumn align={'center'}>
        {resources.map((resource: Resource, index: number) => {
          return this.renderResource(resource, index, false);
        })}
      </UIColumn>
    );
  }

  getRenderedContent() {
    const resources = DataModel.sharedInstance().getResources();
    return (
      <>
        <Breakpoint medium up>
          {this.getStandardContent(resources)}
        </Breakpoint>
        <Breakpoint small down>
          {this.getCompactContent(resources)}
        </Breakpoint>
      </>
    );
  }

  render() {
    const headerContent = (
      <img
        src={'/images/resources-title.png'}
        alt={'Te Takarangi Banner'}
        style={{ height: '100px' }}
      />
    );

    return (
      <Page
        pageSectionIndex={0}
        headerSpec={{
          customContent: headerContent,
          backgroundColor: 'white',
          letterImageLetter: undefined,
        }}
        content={this.getRenderedContent()}
        footerBackgroundColor={'accent'}
        footerTextColor={'white'}
      />
    );
  }
}
