import styled from 'styled-components';

type FlexAlignment = 'flex-start' | 'center' | 'flex-end' | 'stretch';

type RowProps = React.HTMLAttributes<HTMLDivElement> & {
  align?: FlexAlignment;
  vAlign?: FlexAlignment;
  flex?: string;
  padding?: string;
  margin?: string;
  border?: string;
  backgroundColor?: string;
  height?: string;
  isVisible?: boolean;
};

export const UIRowSpace = styled.div`
  width: 10px;
`;

export const UIFlexSpace = styled.div<{ flex?: string }>`
  flex: ${(props) => props.flex ?? '1'};
`;

export const UIRow = styled.div<RowProps>`
  display: ${(props) => (props.isVisible === false ? 'none' : 'flex')};
  flex-direction: row;
  justify-content: ${(props) => props.align ?? 'center'};
  align-items: ${(props) => props.vAlign ?? 'center'};
  ${(props) => (props.flex ? `flex:${props.flex};` : null)};
  padding: ${(props) => props.padding ?? '0'};
  margin: ${(props) => props.margin ?? '0'};
  border: ${(props) => props.border ?? 'none'};
  background-color: ${(props) => props.backgroundColor ?? 'transparent'};
  ${(props) => (props.height ? `height:${props.height};` : null)};
`;

export const UIColumn = styled.div<RowProps>`
  display: ${(props) => (props.isVisible === false ? 'none' : 'flex')};
  flex-direction: column;
  align-items: ${(props) => props.align ?? 'center'};
  justify-content: ${(props) => props.vAlign ?? 'center'};
  ${(props) => (props.flex ? `flex:${props.flex};` : null)};
  padding: ${(props) => props.padding ?? '0'};
  margin: ${(props) => props.margin ?? '0'};
  border: ${(props) => props.border ?? 'none'};
  background-color: ${(props) => props.backgroundColor ?? 'transparent'};
`;

type CellProps = {
  flex?: string;
  height?: string;
  width?: string;
  padding?: string;
  margin?: string;
  alignSelf?: string;
  border?: string;
};
export const UICell = styled.div<CellProps>`
  ${(props) => (props.flex ? `flex:${props.flex};` : null)};
  ${(props) => (props.width ? `width: ${props.width};` : null)};
  ${(props) => (props.width ? `height: ${props.width};` : null)};
  ${(props) => (props.alignSelf ? `align-self: ${props.alignSelf};` : null)};
  padding: ${(props) => props.padding ?? '0'};
  margin: ${(props) => props.margin ?? '0'};
  border: ${(props) => props.border ?? 'none'};
`;
