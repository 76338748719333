import './App.css';
import { APIRequest, RequestResponse } from './model/request';
import { Spinner } from './components/spinner';

import { Theme } from './model/theme';
import { BreakpointProvider } from 'react-socks';
import { Page } from './layout/page.';
import { Navigate, Route, Routes } from 'react-router-dom';
import { SearchPage } from './pages/search-page';
import { ThemesPage } from './pages/themes/themes-page';
import { MatarikiThemePage } from './pages/themes/theme-matariki-page';
import { ReadOurWordsThemePage } from './pages/themes/read-our-words-theme-page';
import { KiaMaumaharaThemePage } from './pages/themes/kia-maumahara-theme-page';
import { TeAoHurihuriThemePage } from './pages/themes/te-ao-hurihuri-theme-page';
import { NgaTokaTumoanaThemePage } from './pages/themes/nga-toka-tumoana-theme';
import { ManaWahineThemePage } from './pages/themes/mana-wahine-page';
import { TeReoRangatiraThemePage } from './pages/themes/te-reo-rangatira-theme';
import { Error404 } from './pages/404';
import { ContactPage } from './pages/contact-page';
import { AboutPage } from './pages/about-page';
import { ResourcesPage } from './pages/resources-page';
import { ThemeProvider } from 'styled-components';

function App() {
  return (
    <BreakpointProvider>
      <ThemeProvider theme={Theme.sharedInstance()}>
        <Routes>
          <Route path='/' element={<Navigate to='/search' />} />
          <Route path='/search' element={<SearchPage />} />
          <Route path='/themes' element={<ThemesPage />} />
          <Route path='/themes/matariki' element={<MatarikiThemePage />} />
          <Route
            path='/themes/read-our-words'
            element={<ReadOurWordsThemePage />}
          />
          <Route
            path='/themes/kia-maumahara'
            element={<KiaMaumaharaThemePage />}
          />
          <Route path='/themes/mana-wahine' element={<ManaWahineThemePage />} />
          <Route
            path='/themes/te-ao-hurihuri'
            element={<TeAoHurihuriThemePage />}
          />
          <Route
            path='/themes/nga-toka-tumoana'
            element={<NgaTokaTumoanaThemePage />}
          />
          <Route
            path='/themes/nga-toka-tumoana'
            element={<NgaTokaTumoanaThemePage />}
          />
          <Route
            path='/themes/te-reo-rangatira'
            element={<TeReoRangatiraThemePage />}
          />
          <Route path='/resources' element={<ResourcesPage />} />
          <Route path='/about' element={<AboutPage />} />
          <Route path='/contact' element={<ContactPage />} />
          <Route path='*' element={<Error404 />} />
        </Routes>
      </ThemeProvider>
    </BreakpointProvider>
  );
}

export default App;
