import { observer } from 'mobx-react';
import React from 'react';
import { Breakpoint } from 'react-socks';
import styled from 'styled-components';
import { UIColumn, UIRow } from '../components/layout';
import { StyledText } from '../components/styled-text';
import { Page } from '../layout/page.';

const BottomImage = styled.img`
  margin-top: 30px;
  width: 100%;
  max-width: 1100px;
  flex: 1;
`;

type ContactPageProps = {};
@observer
export class AboutPage extends React.Component<ContactPageProps> {
  getTextContent() {
    return (
      <>
        <StyledText styleName={'Text3'}>
          Te Takarangi is a celebration of Māori-led scholarship and writing
          across time and genres. This collection of 150 books provides an
          overview of some of the most important Māori leaders, thinkers and
          authors of our time. From the first Maori book published in 1815, to
          current Māori scholars - Te Takarangi reads as a snapshot of Māori
          scholarship in academia.
        </StyledText>
        <StyledText styleName={'Text4'}>
          It profiles some of the earliest interactions between Māori and
          Europeans; highlights the first te reo Mãori publications in the early
          1800s and chronicles the first wave of Maori tertiary scholars,
          university graduates and political and cultural leaders who have paved
          the way for new generations of Māori authors to emerge. These books
          have made our lives richer, helped us to understand our experiences as
          Māori, and helped foster our connections globally with Indigenous
          communities from afar. They are books of mana.
        </StyledText>
        <StyledText styleName={'Text4'}>
          As such they are visionary and capture the aspirations about what was,
          and is, possible for Māori and for the future of Māori and Pākehā in
          Aotearoa New Zealand. We are proud to expand on our original Te
          Takarangi exhibition as launched in Parliament in October 2018. Te
          Takarangi project is led by:
          <p>Professor Jacinta Ruru MNZM, FRSNZ (Raukawa, Ngāti Ranginui)</p>
          <p>Professor Angela Wanhalla (Kāi Tahu)</p>
          <p>Jeanette Wikaira (Ngāti Pukenga, Ngāti Tamaterā, Ngāpuhi)</p>
          <p>Jacinta Beckwith (Ngāti Kahungunu, Ngāti Porou)</p>
        </StyledText>
      </>
    );
  }
  getCompactContent() {
    return (
      <UIColumn>
        {this.getTextContent()}
        <UIColumn
          align={'flex-start'}
          flex={'4'}
          padding={'0 60px 0 0'}
        ></UIColumn>
        <UIColumn vAlign={'flex-end'} flex={'2'}>
          <StyledText
            styleName={'Text2'}
            overrides={{
              textColor: 'accent',
              spaceBefore: 30,
            }}
          >
            A Ngã Pae o te Māramatanga New Zealand Mãori Centre of Research
            Excellence initiated programme, led by researchers at the University
            of Otago, supported by the Royal Society Te Apārangi and Te Rōpū
            Whakahau.
          </StyledText>
        </UIColumn>
      </UIColumn>
    );
  }

  getStandardContent() {
    return (
      <UIRow
        vAlign='flex-end'
        padding={'0 20px 0 20px'}
        style={{ maxWidth: '1100px' }}
      >
        <UIColumn align={'flex-start'} flex={'4'} padding={'0 60px 0 0'}>
          {this.getTextContent()}
        </UIColumn>
        <UIColumn vAlign={'flex-end'} flex={'2'}>
          <StyledText styleName={'Text2'} overrides={{ textColor: 'accent' }}>
            A Ngã Pae o te Māramatanga New Zealand Mãori Centre of Research
            Excellence initiated programme, led by researchers at the University
            of Otago, supported by the Royal Society Te Apārangi and Te Rōpū
            Whakahau.
          </StyledText>
        </UIColumn>
      </UIRow>
    );
  }

  getRenderedContent() {
    return (
      <>
        <Breakpoint medium up>
          {this.getStandardContent()}
        </Breakpoint>
        <Breakpoint small down>
          {this.getCompactContent()}
        </Breakpoint>
        <BottomImage src={'/images/search_view_bottom_image.jpeg'} />
      </>
    );
  }

  render() {
    const headerContent = (
      <img
        src={'/images/te-takarangi-title.png'}
        alt={'Te Takarangi Banner'}
        style={{ height: '100px' }}
      />
    );

    return (
      <Page
        pageSectionIndex={0}
        headerSpec={{
          customContent: headerContent,
          backgroundColor: 'white',
          letterImageLetter: undefined,
        }}
        content={this.getRenderedContent()}
        footerBackgroundColor={'accent'}
        footerTextColor={'white'}
      />
    );
  }
}
