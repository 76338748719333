import * as React from 'react';
import { Text, TextStyle } from './text';

type StyledTextProps = React.HTMLAttributes<HTMLDivElement> & {
  children: React.ReactNode;
  styleName: TextStyleName;
} & Partial<{ disabled: boolean; overrides: Partial<TextStyle> }>;

export const StyledText = (props: StyledTextProps) => {
  const specifiedStyle = getTextStyleWithName(props.styleName);
  const specifiedStyleProps = { ...specifiedStyle, ...props.overrides };
  const { styleName, overrides, ...coreStyledTextProps } = props;
  const combinedProps = {
    ...coreStyledTextProps,
    ...specifiedStyleProps,
  };

  return <Text {...combinedProps}>{props.children}</Text>;
};

/*
––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
Available Text Styles
––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
*/

function getTextStyleWithName(styleName: TextStyleName) {
  return textStyles.get(styleName) ?? textStyles.get('Text1');
}

type TextStyleName =
  | 'Text1'
  | 'Text2'
  | 'Text3'
  | 'Text4'
  | 'Text5'
  | 'ButtonText'
  | 'Title1'
  | 'Title2'
  | 'Title3'
  | 'Title4'
  | 'ButtonText'
  | 'back-link'
  | 'book-title'
  | 'theme-description';

const textStyles = new Map<TextStyleName, TextStyle>([
  [
    'back-link',
    {
      relativeSize: 2,
      textColor: 'accent',
      weight: 'semibold',
    },
  ],
  [
    'Text1',
    {
      relativeSize: 0,
      textColor: 'text1',
      weight: 'regular',
      spaceAfter: 4,
    },
  ],
  [
    'Text2',
    {
      relativeSize: 2,
      textColor: 'text1',
      weight: 'regular',
      spaceAfter: 6,
    },
  ],
  [
    'Text3',
    {
      relativeSize: 3,
      textColor: 'text1',
      weight: 'regular',
      spaceAfter: 6,
    },
  ],
  [
    'Text4',
    {
      relativeSize: 4,
      textColor: 'text1',
      weight: 'regular',
      spaceAfter: 10,
    },
  ],
  [
    'Title1',
    {
      relativeSize: 2,
      textColor: 'text1',
      weight: 'semibold',
      spaceAfter: 4,
    },
  ],
  [
    'Title2',
    {
      relativeSize: 3,
      textColor: 'text1',
      weight: 'semibold',
      spaceAfter: 4,
    },
  ],
  [
    'Title3',
    {
      relativeSize: 5,
      textColor: 'text1',
      weight: 'semibold',
      spaceAfter: 6,
    },
  ],
  [
    'Title4',
    {
      relativeSize: 6,
      textColor: 'text1',
      weight: 'semibold',
      spaceAfter: 6,
    },
  ],
  [
    'book-title',
    {
      relativeSize: 8,
      textColor: '80%',
      weight: 'semibold',
      spaceAfter: 12,
    },
  ],
  [
    'theme-description',
    {
      relativeSize: 6,
      textColor: '80%',
      weight: 'light',
      spaceAfter: 20,
    },
  ],
  [
    'ButtonText',
    {
      relativeSize: 2,
      textColor: 'white',
      weight: 'semibold',
    },
  ],
]);
