import { makeObservable, observable } from 'mobx';
import DataModel from './data-model';

export type Language = 'english' | 'maori';

type Phrase = {
  english: string;
  maori: string;
};

class Translator {
  private static instance: Translator;

  private _translations: Map<string, Phrase>;

  private constructor() {
    this._translations = new Map<string, Phrase>();
    this._translations.set('nav-button-search', {
      english: 'search',
      maori: 'rapua',
    });
    this._translations.set('nav-button-about', {
      english: 'about te takarangi',
      maori: 'mõ te takarangi',
    });
    this._translations.set('nav-button-discover', {
      english: 'discover',
      maori: 'tūhura',
    });
    this._translations.set('nav-button-themes', {
      english: 'themes',
      maori: 'tāhu',
    });
    this._translations.set('nav-button-resources', {
      english: 'resources',
      maori: 'rauemi',
    });
    this._translations.set('nav-button-news', {
      english: 'news',
      maori: 'kawepūrongo',
    });
    this._translations.set('nav-button-contact', {
      english: 'contact',
      maori: 'kōpāpā',
    });
    this._translations.set('search-term-placeholder', {
      english: 'Search books...',
      maori: 'Rapua...',
    });
    this._translations.set('search-no-books-found', {
      english: 'No books were found, try a different search term',
      maori: 'No books were found, try a different search term',
    });
    this._translations.set('new-search', {
      english: 'New search',
      maori: 'Rapu hou',
    });
    this._translations.set('search-the-collection', {
      english: 'Search the collection in Māori or English',
      maori: 'Search the collection in Māori or English',
    });
    this._translations.set('books-found', {
      english: 'Books found',
      maori: 'Books found',
    });
    this._translations.set('back-to-results', {
      english: 'Back to results',
      maori: 'Back to results',
    });
    this._translations.set('searching-for-books', {
      english: 'Searching the book collection',
      maori: 'Searching the book collection',
    });
    this._translations.set('view-this-collection', {
      english: 'View this theme',
      maori: 'View this theme',
    });
    this._translations.set('back-to-themes', {
      english: 'Back to themes',
      maori: 'Back to themes',
    });
    this._translations.set('nav-back', {
      english: 'Back',
      maori: 'Back',
    });
    this._translations.set('404-continue', {
      english: 'Continue',
      maori: 'Continue',
    });
  }

  static sharedInstance(): Translator {
    if (!Translator.instance) {
      Translator.instance = new Translator();
    }
    return Translator.instance;
  }

  public textForKey(key: string) {
    const language = DataModel.sharedInstance().language;
    const phrase = this._translations.get(key);
    const text = language === 'english' ? phrase?.english : phrase?.maori;
    return text ?? `???? ${key} ????`;
  }
}

export function tt(key: string) {
  return Translator.sharedInstance().textForKey(key);
}

// const myClassInstance = Translations.Instance;
export default Translator;
