export const kUIAnimationDuration = 0.35;
export const kUShortIAnimationDuration = 0.175;
export const kUIDisabledOpacity = 0.5;

// Layout constants
export const kHeaderHeight = 165;
export const kHeaderNavBarHeight = 40;
export const kNavBarAfterSpace = 6;

export const kHeaderButtonHeight = 16;
