import * as React from 'react';
import styled from 'styled-components';

import { kUIDisabledOpacity, kUIAnimationDuration } from '../lib/constants';
import { NamedColor, Theme } from '../model/theme';

const kUIFontName = 'UIFont';
export const kBaseTextSize = 13;
export const kTextBetweenParagraphSpacing = 4;

const kLineHeightMultiplier = 1.2;
const kBaseTextWeight = 400;

export type TextSize =
  | -3
  | -2
  | -1
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 14
  | 20;

export type TextWeight =
  | 'thin'
  | 'extralight'
  | 'light'
  | 'regular'
  | 'medium'
  | 'semibold'
  | 'bold'
  | 'extrabold'
  | 'black';

export type TextAlignment = 'left' | 'center' | 'right';
export type TextVerticalAlignment = 'top' | 'center' | 'bottom';

type TextDisplayMode = 'inline' | 'block' | 'inline-block';

export type TextStyle = {
  displayMode?: TextDisplayMode;
  firstLineIndent?: number;
  lineHeightInEm?: number;
  relativeSize?: TextSize;
  textColor?: NamedColor;
  weight?: TextWeight;
  alignment?: TextAlignment;
  spaceBefore?: number;
  spaceAfter?: number;
  leftIndent?: number;
  rightIndent?: number;
  backgroundColor?: string;
  italic?: boolean;
  elementWidth?: string | undefined;
  maxElementWidth?: number | undefined;
  disabled?: boolean;
  customColor?: string;
  forceSingleLine?: boolean;
};

/*
––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
Text Type functions
––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
*/

export function getTextSizeFromRelativeSize(
  sizeType: TextSize | undefined
): number {
  if (sizeType === undefined || sizeType === 0) {
    return kBaseTextSize;
  }
  return kBaseTextSize + sizeType * 2;
}

export function getTextFamilyFromWeight(
  weightType: TextWeight | undefined,
  italics: boolean | undefined = false
): string {
  return `${kUIFontName}${weightType ?? 'regular'}${
    italics ? 'Italic' : ''
  },sans-serif`;
}

export const kTextStyleDefault: TextStyle = {
  displayMode: 'block',
  firstLineIndent: 0,
  lineHeightInEm: kLineHeightMultiplier,
  relativeSize: 0,
  textColor: 'text1',
  weight: 'regular',
  alignment: 'left',
  spaceBefore: 0,
  spaceAfter: 0,
  leftIndent: 0,
  rightIndent: 0,
  backgroundColor: 'transparent',
  italic: false,
  elementWidth: 'fit-content',
  maxElementWidth: undefined,
  disabled: false,
  customColor: Theme.color('text1'),
  forceSingleLine: false,
};

function getClipContentsCSS() {
  return `
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; 
    `;
}

/*
––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
Text Styled Component
––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
*/

export const Text = styled.div<
  TextStyle & React.HTMLAttributes<HTMLDivElement>
>`
  display: ${(props) => props.displayMode ?? kTextStyleDefault.displayMode};
  ${(props) => (props.elementWidth ? `width: ${props.elementWidth};` : null)}
  text-indent: ${(props) =>
    props.firstLineIndent ?? kTextStyleDefault.firstLineIndent}px;
  ${(props) =>
    props.maxElementWidth ? `max-width: ${props.maxElementWidth}px;` : null}
  font-weight: ${kBaseTextWeight};
  font-family: ${(props) =>
    getTextFamilyFromWeight(props.weight, props.italic)};
  background-color: ${(props) =>
    props.backgroundColor ?? kTextStyleDefault.backgroundColor};
  font-size: ${(props) => getTextSizeFromRelativeSize(props.relativeSize)}px;
  line-height: ${(props) =>
    props.lineHeightInEm ?? kTextStyleDefault.lineHeightInEm};
  color: ${(props) => Theme.color(props.textColor ?? 'text1')};
  text-align: ${(props) => props.alignment ?? kTextStyleDefault.alignment};
  margin-top: ${(props) =>
    props.spaceBefore
      ? `${props.spaceBefore}px`
      : kTextStyleDefault.spaceBefore};
  margin-bottom: ${(props) =>
    props.spaceAfter ? `${props.spaceAfter}px` : kTextStyleDefault.spaceAfter};
  margin-left: ${(props) =>
    props.leftIndent ? `${props.leftIndent}px` : kTextStyleDefault.leftIndent};
  margin-right: ${(props) =>
    props.rightIndent
      ? `${props.rightIndent}px`
      : kTextStyleDefault.rightIndent};
  opacity: ${(props) => (props.disabled ? kUIDisabledOpacity : 1)};
  transition: opacity ${kUIAnimationDuration}s ease-in-out,
    color ${kUIAnimationDuration}s ease-in-out;
  ${(props) => (props.forceSingleLine ? getClipContentsCSS() : null)}
  &:focus {
    border: none;
    outline: none;
  }
`;
