import { observer } from 'mobx-react';
import styled from 'styled-components';
import { keyframes } from 'styled-components';
import { Theme } from '../model/theme';

type SpinnerProps = {
  sizeInPixels: number;
  borderWidth?: number;
  color?: string;
};

const Scale = keyframes`
  0% {
        transform: scale(0);
        opacity: 0;
      }

   50% {
      transform: scale(0.5);
      opacity: 1;
    }
    
    % {
      transform: scale(1);
      opacity: 0;
    }
`;

const SpinnerWrapper = styled.div<SpinnerProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.sizeInPixels}px;
  height: ${(props) => props.sizeInPixels}px;
`;

const SpinnerInner = styled.div<SpinnerProps & { speed: string }>`
  position: absolute;
  left: 0;
  top: 0;
  width: ${(props) => props.sizeInPixels}px;
  height: ${(props) => props.sizeInPixels}px;
  border: ${(props) => props.borderWidth ?? 4}px solid ${(props) => props.color};
  animation: ${Scale} ${(props) => props.speed} 0.5s linear infinite;
  border-radius: 50%;
`;

export const Spinner = observer((props: SpinnerProps) => {
  const color = props.color ?? Theme.color('accent');
  const borderWidth = props.borderWidth ?? 2;
  const sizeInPixels = props.sizeInPixels ?? 64;
  return (
    <SpinnerWrapper sizeInPixels={sizeInPixels}>
      <SpinnerInner
        sizeInPixels={sizeInPixels}
        speed={'2s'}
        borderWidth={borderWidth}
        color={color}
      />
      <SpinnerInner
        sizeInPixels={sizeInPixels}
        speed={'1s'}
        borderWidth={borderWidth}
        color={color}
      />
    </SpinnerWrapper>
  );
});
