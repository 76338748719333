import { Component } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import {
  kUIDisabledOpacity,
  kUShortIAnimationDuration,
} from '../lib/constants';
import { Theme } from '../model/theme';
import { UIRow } from './layout';
import { MdArrowBack, MdArrowForward } from 'react-icons/md';
import { StyledText } from './styled-text';
import { ResultSet } from '../model/data-model';

const PageButton = styled.button<{ size: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 6px;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: 50%;
  border: 1px solid ${() => Theme.color('accent', 0.2)};
  background-color: white;
  transition: all ${kUShortIAnimationDuration}s linear;
  cursor: pointer;
  &:disabled {
    opacity: ${kUIDisabledOpacity};
    cursor: default;
  }
  &:hover {
    background-color: ${() => Theme.color('10%', 0.5)};
  }
`;

const Part = styled.div`
  :nth-child(2) {
    margin: 0 12px;
  }
`;

type PaginationControlsProps = {
  pageIndex: number;
  numberOfPages: number;
  controlIsDisabled?: boolean;
  pageWasSelected?: (newPageIndex: number) => void;
};

@observer
class PaginationControls extends Component<PaginationControlsProps> {
  handleClick(newPageIndex: number) {
    if (this.props.pageWasSelected) {
      this.props.pageWasSelected(newPageIndex);
    }
  }
  render() {
    const { pageIndex, numberOfPages, controlIsDisabled } = this.props;
    const size = 32;
    const iconColor = Theme.color('accent', 0.8);
    const currentPageIndex = pageIndex;
    return (
      <UIRow align={'flex-end'}>
        <Part>
          <PageButton
            size={size}
            disabled={currentPageIndex === 1 || controlIsDisabled}
            onClick={() => this.handleClick(currentPageIndex - 1)}
          >
            <MdArrowBack color={iconColor} fontSize={size} />
          </PageButton>
        </Part>
        <Part>
          <StyledText styleName={'Text1'} overrides={{ textColor: 'accent' }}>
            {`${currentPageIndex} / ${numberOfPages}`}
          </StyledText>
        </Part>
        <Part>
          <PageButton
            size={size}
            disabled={currentPageIndex >= numberOfPages || controlIsDisabled}
            onClick={() => this.handleClick(currentPageIndex + 1)}
          >
            <MdArrowForward color={iconColor} fontSize={size} />
          </PageButton>
        </Part>
      </UIRow>
    );
  }
}

export default PaginationControls;
