import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';
import { UIColumn } from '../components/layout';
import { StyledText } from '../components/styled-text';
import { Page } from '../layout/page.';
import { Theme } from '../model/theme';

const StyledLink = styled.a`
  text-decoration: none;
  color: ${() => Theme.color('accent')};
`;

const Background = styled.div`
  position: fixed;
  bottom: 0px;
  background: url('/images/contact-bck.jpeg');
`;
type ContactPageProps = {};
@observer
export class ContactPage extends React.Component<ContactPageProps> {
  getRenderedContent() {
    return (
      <UIColumn align={'flex-start'}>
        <Background />
        <StyledText styleName={'Text4'} style={{ margin: '0 40px 0 100px' }}>
          A Ngā Pae o te Maramatanga New Zealand Māori Centre of Research
          Excellence initiated programme, led by researchers at the University
          of Otago, supported by the Royal Society Te Aparangi and Te Rōpu
          Whakahau.
        </StyledText>
        <StyledText styleName={'Text4'} style={{ margin: '40px 40px 0 100px' }}>
          Hei kona ano, imera:{' '}
          <StyledLink href='mailto:info@tetakarangi.nz'>
            info@tetakarangi.nz
          </StyledLink>
          .
        </StyledText>
      </UIColumn>
    );
  }

  render() {
    const headerContent = (
      <img
        src={'/images/kopapa.png'}
        alt={'Te Takarangi Banner'}
        style={{ height: '100px' }}
      />
    );

    return (
      <Page
        pageSectionIndex={0}
        headerSpec={{
          customContent: headerContent,
          backgroundColor: 'white',
          letterImageLetter: undefined,
        }}
        content={this.getRenderedContent()}
        footerBackgroundColor={'accent'}
        footerTextColor={'white'}
      />
    );
  }
}
