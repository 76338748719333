import styled from 'styled-components';
import {
  kUIDisabledOpacity,
  kUShortIAnimationDuration,
} from '../lib/constants';
import { Theme } from '../model/theme';

export const RoundDiv = styled.div<{ size: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: 50%;
  border: 1px solid ${() => Theme.color('accent', 0.2)};
  background-color: white;
  transition: all ${kUShortIAnimationDuration}s linear;
  cursor: pointer;
  &:disabled {
    opacity: ${kUIDisabledOpacity};
    cursor: default;
  }
  &:hover {
    background-color: ${() => Theme.color('10%', 0.5)};
  }
`;
