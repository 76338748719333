import React, { Component } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { Spinner } from './spinner';
import { kUIAnimationDuration } from '../lib/constants';
import { NamedColor, Theme } from '../model/theme';

const kAspectRatio = 1.4;
const kDefaultThumbnailHeight = 300;

export function imageThumbnailWidthWithHeight(height: number) {
  return height / kAspectRatio;
}

type ImageStatus = 'loading' | 'loaded' | 'error';

const ImageContainer = styled.div<{
  height: number;
  margin: string;
  isInteractive: boolean;
  backgroundColor?: string;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: ${(props) => props.height}px;
  width: ${(props) => Math.floor(props.height / kAspectRatio)}px;
  border-radius: 5px;
  overflow: hidden;
  margin: ${(props) => props.margin};
  box-shadow: 0 0 8px ${() => Theme.color('40%', 0.6)};
  transition: transform ${kUIAnimationDuration}s linear;
  cursor: ${(props) => (props.isInteractive ? 'pointer' : 'default')};
  background-color: ${(props) => props.backgroundColor ?? 'transparent'};
  &:hover {
    ${(props) =>
      props.isInteractive ? `transform: scale(1.10) rotate(-2deg);` : null}
    z-index:1000;
    box-shadow: 0 0 6px #101010;
  }
  &:active {
    ${(props) => (props.isInteractive ? `filter: brightness(1.2);` : null)}
  }
`;

const BookLoadingWrapper = styled.div`
  position: absolute;

  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const BookImage = styled.img<{ height: number; isLoading: boolean }>`
  opacity: ${(props) => (props.isLoading ? 0 : 1.0)};
  transition: opacity 0.5s linear;
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

type Props = {
  url: string;
  title: string;
  subtitle?: string;
  heightInPixels: number;
  margin?: string;
  onClick?: () => void;
  onHover?: () => void;
  onMouseLeave?: () => void;
  borderRadiusInPixels?: number;
  useDropShadow?: boolean;
  border?: string;
  backgroundColorName?: NamedColor;
};

type State = { imageStatus: ImageStatus };
@observer
class BookThumbnail extends Component<Props, State> {
  private _isMounted: boolean;

  constructor(props: Props) {
    super(props);
    this.state = { imageStatus: 'loading' };
    this._isMounted = true;
  }

  handleImageErrored() {
    if (this._isMounted) {
      this.setState({ imageStatus: 'error' });
    }
  }

  handleImageLoaded() {
    if (this._isMounted) {
      const delay = Math.random() * 150;
      setTimeout(() => this.setState({ imageStatus: 'loaded' }), delay);
    }
  }

  loadingContent() {
    return (
      <BookLoadingWrapper>
        <Spinner sizeInPixels={25} />
      </BookLoadingWrapper>
    );
  }

  handleClick() {
    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleMouseOver() {
    if (this.props.onHover) {
      this.props.onHover();
    }
  }

  handleMouseLeave() {
    if (this.props.onMouseLeave) {
      this.props.onMouseLeave();
    }
  }

  render() {
    const height = this.props.heightInPixels ?? kDefaultThumbnailHeight;
    const isInteractive = this.props.onClick !== undefined;
    const margin = this.props.margin ?? '24px 24px 6px 24px';
    const bgColorName = this.props.backgroundColorName ?? '10%';
    const backgroundColor = Theme.color(bgColorName);
    return (
      <ImageContainer
        height={height}
        isInteractive={isInteractive}
        onClick={() => this.handleClick()}
        margin={margin}
        backgroundColor={backgroundColor}
        onMouseEnter={() => this.handleMouseOver()}
        onMouseLeave={() => this.handleMouseLeave()}
      >
        <BookImage
          alt={'Book'}
          src={this.props.url}
          onLoad={() => this.handleImageLoaded()}
          onError={() => this.handleImageErrored()}
          isLoading={this.state.imageStatus === 'loading'}
          height={height}
        />
        {this.state.imageStatus === 'loading' ? this.loadingContent() : null}
      </ImageContainer>
    );
  }
}

export default BookThumbnail;
