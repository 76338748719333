import { DateTime } from 'luxon';
import DataModel from './data-model';
import { IDable } from './interfaces';

export class Resource implements IDable {
  id: number;
  created_at: DateTime | undefined;
  updated_at: DateTime | undefined;
  title_eng: string;
  title_mao: string;
  description_eng: string;
  description_mao: string;
  linkURL: string;
  image_id: number;

  constructor(object: any) {
    this.id = object?.id ?? 0;
    const created_at = object.created_at
      ? DateTime.fromISO(object.created_at)
      : undefined;
    this.created_at = created_at;
    const updated_at = object.updated_at
      ? DateTime.fromISO(object.updated_at)
      : undefined;
    this.updated_at = updated_at;
    this.title_eng = object?.title_eng ?? '';
    this.title_mao = object?.title_mao ?? '';
    this.description_eng = object?.introduction_eng ?? '';
    this.description_mao = object?.introduction_mao ?? '';
    this.linkURL = object?.linkURL ?? '';
    this.image_id = object?.image_id ?? '';
  }

  hasImage() {
    return this.image_id > 0;
  }

  public temp_imageURL() {
    return `/images/resources/${this.image_id}.png`;
  }

  thumbnailURL(atSize: number) {
    if (this.hasImage()) {
      return `${DataModel.sharedInstance().mediaURL(
        this.image_id
      )}/thumbnail/${atSize}/png`;
    } else {
      return '/images/generic_media/_generic_book.png';
    }
  }

  thumbnailWithHeightURL(atSize: number) {
    if (this.hasImage()) {
      return `${DataModel.sharedInstance().mediaURL(
        this.image_id
      )}/thumbnailWithHeight/${atSize}/png`;
    } else {
      return '/images/generic_media/_generic_book.png';
    }
  }

  languageAwareTitle() {
    const currentLanguage = DataModel.sharedInstance().language;
    if (currentLanguage === 'english') {
      return this.title_eng ?? this.title_mao ?? 'No title';
    }
    return this.title_mao ?? this.title_eng ?? 'No title';
  }

  languageAwareDescription() {
    const currentLanguage = DataModel.sharedInstance().language;
    if (currentLanguage === 'english') {
      return this.description_eng ?? this.description_mao ?? 'No title';
    }
    return this.description_mao ?? this.description_eng ?? 'No title';
  }
}
