import { DateTime } from 'luxon';
import { Collection } from './collection';
import DataModel from './data-model';
import { IDable } from './interfaces';
import { MediaItem } from './media-item';

export class Book implements IDable {
  id: number;
  created_at: DateTime | undefined;
  updated_at: DateTime | undefined;
  title_eng: string;
  title_mao: string;
  author: string;
  introduction_eng: string;
  introduction_mao: string;
  description_eng: string;
  description_mao: string;
  publicationInfo: string;
  publicationDate: DateTime | undefined;
  availability: string;
  keywords: string;
  primary_image_id: number;

  media: MediaItem[];
  collections: Collection[];
  relatedBooks: Book[];

  constructor(object: any) {
    this.id = object?.id ?? 0;
    const created_at = object.created_at
      ? DateTime.fromISO(object.created_at)
      : undefined;
    this.created_at = created_at;
    const updated_at = object.updated_at
      ? DateTime.fromISO(object.updated_at)
      : undefined;
    this.updated_at = updated_at;
    this.title_eng = object?.title_eng ?? '';
    this.title_mao = object?.title_mao ?? '';
    this.author = object?.author ?? '';
    this.introduction_eng = object?.introduction_eng ?? '';
    this.introduction_mao = object?.introduction_mao ?? '';
    this.description_eng = object?.description_eng ?? '';
    this.description_mao = object?.description_mao ?? '';
    this.publicationInfo = object?.publicationInfo ?? '';
    const publicationDate = object.publicationDate
      ? DateTime.fromISO(object.publicationDate)
      : undefined;
    this.publicationDate = publicationDate;
    this.availability = object?.availability ?? '';
    this.keywords = object?.keywords ?? '';
    this.primary_image_id = object?.primary_image_id ?? 0;

    this.media = [];
    this.collections = [];
    this.relatedBooks = [];
  }

  loadFullDataFromResponse(responseObject: any) {
    if (responseObject.media && Array.isArray(responseObject.media)) {
      responseObject.media.forEach((element: any) => {
        const media_item = new MediaItem(element);
        this.media.push(media_item);
      });
    }
    this.collections.sort((a: Collection, b: Collection) =>
      a.title_eng > b.title_eng ? 1 : -1
    );
    if (
      responseObject.collections &&
      Array.isArray(responseObject.collections)
    ) {
      responseObject.media.forEach((element: any) => {
        const collections = new Collection(element);
        this.collections.push(collections);
      });
    }
    this.media.sort((a: MediaItem, b: MediaItem) =>
      a.title_eng > b.title_eng ? 1 : -1
    );
    if (
      responseObject.relatedBooks &&
      Array.isArray(responseObject.relatedBooks)
    ) {
      responseObject.relatedBooks.forEach((element: any) => {
        const book = new Book(element);
        this.relatedBooks.push(book);
      });
    }
    this.relatedBooks.sort((a: Book, b: Book) =>
      a.title_eng > b.title_eng ? 1 : -1
    );
  }

  hasPrimaryImage() {
    return this.primary_image_id > 0;
  }

  thumbnailURL(atSize: number) {
    if (this.hasPrimaryImage()) {
      return `${DataModel.sharedInstance().mediaURL(
        this.primary_image_id
      )}/thumbnail/${atSize}/png`;
    } else {
      return '/images/generic_media/_generic_book.png';
    }
  }

  thumbnailWithHeightURL(atSize: number) {
    if (this.hasPrimaryImage()) {
      return `${DataModel.sharedInstance().mediaURL(
        this.primary_image_id
      )}/thumbnailWithHeight/${atSize}/png`;
    } else {
      return '/images/generic_media/_generic_book.png';
    }
  }

  languageAwareTitle() {
    const currentLanguage = DataModel.sharedInstance().language;
    if (currentLanguage === 'english') {
      return this.title_eng ?? this.title_mao ?? 'No title';
    }
    return this.title_mao ?? this.title_eng ?? 'No title';
  }

  getDescriptionParagraphs() {
    // str.split(/\r?\n/)
    return this.description_eng.split('. ');
  }

  getPublicationInfoParagraphs() {
    return this.publicationInfo.split('.   ');
  }

  getIntroductionParagraphs() {
    return this.introduction_eng.split('. ');
  }
}
