import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';
import { UIColumn, UIRow } from '../../components/layout';
import { NavButton } from '../../components/nav-button';
import { StyledText } from '../../components/styled-text';
import { contentForHeaderImage } from '../../layout/page-header';
import { Page } from '../../layout/page.';
import { Collection } from '../../model/collection';
import DataModel, { CollectionResultSet } from '../../model/data-model';
import { BookTheme } from '../../model/interfaces';
import { NamedColor, Theme } from '../../model/theme';
import { tt } from '../../model/translator';
import { useCurrentWidth } from 'react-socks';
import { Book } from '../../model/book';
import BookThumbnail, {
  imageThumbnailWidthWithHeight,
} from '../../components/book-thumbnail';
import { BookDisplay } from '../../components/book-display';
import { timeStamp } from 'console';
import { Link } from 'react-router-dom';
import {
  ThemeDescription,
  ThemeNavigationRow,
} from '../../components/theme-components';
import { BookBrowser } from '../../components/book-browser';

type NgaTokaTumoanaThemePageState = {
  collection: Collection | undefined;
  bookToDisplay: Book | undefined;
};
@observer
export class NgaTokaTumoanaThemePage extends React.Component<
  {},
  NgaTokaTumoanaThemePageState
> {
  private _collectionID: number;

  constructor(props: {}) {
    super(props);
    this._collectionID = 8;
    this.state = { collection: undefined, bookToDisplay: undefined };
  }

  componentDidMount() {
    const collection = DataModel.sharedInstance().getCachedCollectionWithID(
      this._collectionID
    );
    this.setState({ collection: collection });
  }

  reOrderedBooks() {
    if (this.state.collection) {
      const reordered = [];
      const existing = this.state.collection.books;
      reordered.push(existing[0]);
      reordered.push(existing[1]);
      reordered.push(existing[3]);
      reordered.push(existing[2]);
      reordered.push(existing[4]);
      reordered.push(existing[5]);
      reordered.push(existing[7]);
      reordered.push(existing[6]);
      reordered.push(existing[8]);
      reordered.push(existing[11]);
      reordered.push(existing[9]);
      reordered.push(existing[10]);
      return reordered;
    }
  }

  showBook(book: Book, index: number) {
    this.setState({ bookToDisplay: book });
  }

  clearBook() {
    this.setState({ bookToDisplay: undefined });
  }

  goBack() {}

  cellColorForIndex(index: number): NamedColor {
    // theme-nga-toka-tumoana-cell2
    const rowIndex = Math.floor(index / 2);
    const rowIsOdd = rowIndex % 2 === 0;
    const cellIsLeftCell = index % 2 === 0;
    if (cellIsLeftCell) {
      return rowIsOdd
        ? 'theme-nga-toka-tumoana-cell2'
        : 'theme-nga-toka-tumoana-cell1';
    }
    return rowIsOdd
      ? 'theme-nga-toka-tumoana-cell1'
      : 'theme-nga-toka-tumoana-cell2';
  }

  getReorderedCellContent(
    index: number,
    bookContent: React.ReactNode,
    authorContent: React.ReactNode
  ) {
    const rowIndex = Math.floor(index / 2);
    const rowIsOdd = rowIndex % 2 === 0;

    const layout1 = (
      <>
        {bookContent} {authorContent}
      </>
    );
    const layout2 = (
      <>
        {authorContent} {bookContent}
      </>
    );

    return rowIsOdd ? layout1 : layout2;
  }

  getBookGridCell(book: Book, index: number) {
    const bookHeight = 220;
    const bgColorName = this.cellColorForIndex(index);
    const bookContent = (
      <BookThumbnail
        title={book.languageAwareTitle()}
        url={book.thumbnailWithHeightURL(bookHeight)}
        onClick={() => this.showBook(book, index)}
        heightInPixels={bookHeight}
        key={`book-${book.id}`}
        margin={'0'}
      />
    );
    const authorContent = (
      <img
        src={`/images/theme/nga-toka-tumoana/authors/author-${book.id}.png`}
        style={{ width: '150px' }}
        alt={''}
      />
    );

    return (
      <UIRow
        backgroundColor={Theme.color(bgColorName)}
        style={{ padding: '20px' }}
        key={`book-grid-${book.id}`}
      >
        {this.getReorderedCellContent(index, bookContent, authorContent)}
      </UIRow>
    );
  }

  getBookGrid() {
    const books = this.reOrderedBooks() ?? [];
    return (
      <UIRow
        style={{
          flexWrap: 'wrap',
          maxWidth: '1500px',
          backgroundColor: Theme.color('theme-nga-toka-tumoana-bck'),
          padding: '10px',
        }}
      >
        {books.map((book: Book, index) => this.getBookGridCell(book, index))}
      </UIRow>
    );
  }

  getContent() {
    return (
      <UIColumn>
        <UIColumn padding={'20px'}>
          {this.state.bookToDisplay ? (
            <BookDisplay
              book={this.state.bookToDisplay}
              backButtonWasClicked={() => this.clearBook()}
            />
          ) : (
            <>
              <ThemeNavigationRow />
              <ThemeDescription
                description={this.state.collection?.description_eng}
              />
              {this.getBookGrid()}
            </>
          )}
        </UIColumn>
      </UIColumn>
    );
  }

  render() {
    return (
      <Page
        pageSectionIndex={2}
        headerSpec={{
          backgroundColor: 'accent',
          customContent: contentForHeaderImage(
            '/images/theme/nga-toka-tumoana/header.png'
          ),
          letterImageLetter: 't',
        }}
        content={this.getContent()}
        footerBackgroundColor={'accent'}
        footerTextColor={'white'}
      />
    );
  }
}
