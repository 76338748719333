import { DateTime } from 'luxon';
import { Book } from './book';
import { IDable } from './interfaces';

type CollectionMetaData = {
  letter: string;
  name: string;
  description: string;
};

export class Collection implements IDable {
  id: number;
  title_eng: string;
  title_mao: string;
  description_eng: string;
  description_mao: string;
  keywords: string;
  theme_name: string;
  image_slug: string;
  created_at: DateTime | undefined;
  updated_at: DateTime | undefined;
  metadata: CollectionMetaData;
  books: Book[];

  constructor(objectData: any) {
    this.id = objectData.id ?? undefined;
    this.title_eng = objectData.title_eng ?? '';
    this.title_mao = objectData.title_mao ?? '';
    this.description_eng = objectData.description_eng ?? '';
    this.description_mao = objectData.description_mao ?? '';
    this.keywords = objectData.keywords ?? '';
    this.theme_name = objectData.theme_name ?? '';
    this.image_slug = objectData.image_slug ?? undefined;
    const created_at = objectData.created_at
      ? DateTime.fromISO(objectData.created_at)
      : undefined;
    this.created_at = created_at;
    const updated_at = objectData.updated_at
      ? DateTime.fromISO(objectData.updated_at)
      : undefined;
    this.updated_at = updated_at;
    this.metadata = this.extractMetadata(this.theme_name);
    this.books = [];
  }

  extractMetadata(theme_name: string): CollectionMetaData {
    const info = this.theme_name.split('|');
    return {
      letter: info[0],
      name: info[1],
      description: info[2],
    };
  }

  loadFullDataFromResponse(responseObject: any) {
    if (responseObject.books && Array.isArray(responseObject.books)) {
      responseObject.books.forEach((element: any) => {
        const book = new Book(element);
        this.books.push(book);
      });
    }
  }

  hasImage() {
    if (this.image_slug === undefined) {
      return false;
    }
    if (this.image_slug === '') {
      return false;
    }
    return true;
  }

  squareThumbnailURLAtSize(size: number) {
    if (this.hasImage()) {
      return `/media/${this.image_slug}/collectionThumbnail/${size}/jpg`;
    } else {
      return '/img/_generic_collection.png';
    }
  }
}
