import { DateTime } from 'luxon';

export type MediaItemType = 'image' | 'audio' | 'video' | 'pdf';

export class MediaItem {
  id: number;
  book_id: number;

  type: MediaItemType;
  title_eng: string;
  title_mao: string;
  originalfilename: string;
  originalmimetype: string;
  filesize: number;
  slug: string;
  created_at: DateTime | undefined;
  updated_at: DateTime | undefined;

  constructor(objectData: any) {
    this.id = objectData.id;
    this.book_id = objectData.book_id;

    this.type = objectData.type ?? '';
    this.title_eng = objectData.title_eng ?? '';
    this.title_mao = objectData.title_mao ?? '';
    this.originalfilename = objectData.originalfilename ?? '';
    this.originalmimetype = objectData.originalmimetype ?? '';
    this.filesize = objectData.filesize ?? 0;
    this.slug = objectData.slug ?? '';
    const created_at = objectData.created_at
      ? DateTime.fromISO(objectData.created_at)
      : undefined;
    this.created_at = created_at;
    const updated_at = objectData.updated_at
      ? DateTime.fromISO(objectData.updated_at)
      : undefined;
    this.updated_at = updated_at;
  }

  getSquareThumbnailSrc(size: number) {
    switch (this.type.toLowerCase()) {
      case 'image':
        return `/media/${this.id}/squarethumbnail/${size}/jpg`;
      case 'audio':
        return '/img/_generic_audio.png';
      case 'video':
        return '/img/_generic_video.png';
      case 'pdf':
        return '/img/_generic_pdf.png';
      default:
        return '/img/_generic_unknown.png';
    }
  }
}
