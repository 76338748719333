import * as React from 'react';
import styled from 'styled-components';
import { HeaderButton } from '../components/header-button';
import { UIColumn, UIRow, UIRowSpace } from '../components/layout';
import { LanguageButton } from '../components/language-button';
import { observer } from 'mobx-react';
import DataModel from '../model/data-model';
import Translator, { Language, tt } from '../model/translator';
import { kHeaderNavBarHeight, kNavBarAfterSpace } from '../lib/constants';
import { slide as Menu } from 'react-burger-menu';
import { Breakpoint } from 'react-socks';
import { Theme } from '../model/theme';
import { Link } from 'react-router-dom';
import { NavButton } from '../components/nav-button';

const NavBarContainer = styled.div`
  background-color: ${() => Theme.color('accent')};
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: ${kHeaderNavBarHeight + kNavBarAfterSpace}px;
  max-height: ${kHeaderNavBarHeight + kNavBarAfterSpace}px;
  overflow: hidden;
  padding-bottom: ${kNavBarAfterSpace}px;
  padding: 0;
`;

const BetweenButtonGroupSpacer = styled.div`
  flex: 1;
`;

type NavBarProps = {
  selectedIndex: number;
};

@observer
export class NavBar extends React.Component<NavBarProps> {
  handleLanguageChange(newLanguage: Language) {
    if (newLanguage !== DataModel.sharedInstance().language) {
      DataModel.sharedInstance().setLanguage(newLanguage);
    }
  }

  getMainButtons() {
    const translator = Translator.sharedInstance();
    return [
      translator.textForKey('nav-button-search'),
      translator.textForKey('nav-button-about'),
      translator.textForKey('nav-button-discover'),
      translator.textForKey('nav-button-themes'),
      translator.textForKey('nav-button-resources'),
      translator.textForKey('nav-button-news'),
      translator.textForKey('nav-button-contact'),
    ];
  }

  setNewSection(newSectionIndex: number) {
    if (DataModel.sharedInstance().currentSectionIndex !== newSectionIndex) {
      DataModel.sharedInstance().setSectionIndex(newSectionIndex);
    }
  }

  showConstrained() {
    var styles = {
      bmBurgerButton: {
        position: 'absolute',
        width: '20px',
        height: '20px',
        left: '10px',
        top: '10px',
      },
      bmBurgerBars: {
        background: '#ffffff',
      },
      bmBurgerBarsHover: {
        background: '#a90000',
      },
      bmCrossButton: {
        height: '24px',
        width: '24px',
      },
      bmCross: {
        background: '#bdc3c7',
      },
      bmMenuWrap: {
        position: 'fixed',
      },
      bmMenu: {
        background: 'rgba(0,0,0,0.8)',
        padding: '20px 0 0 0',
        fontSize: '2em',
        marginLeft: '-20px',
      },
      bmMorphShape: {
        fill: '#373a47',
      },
      bmItemList: {
        color: '#b8b7ad',
        padding: '0.8em',
      },
      bmItem: {
        display: 'inline-block',
      },
      bmOverlay: {
        background: 'rgba(0, 0, 0, 0.6)',
      },
    };

    return (
      <Menu styles={styles} width={230}>
        <UIColumn align={'flex-start'}>
          <div style={{ padding: '8px 0' }}>
            <Link to={'/search'} style={{ textDecoration: 'none' }}>
              <HeaderButton text={tt('nav-button-search')} isOn={false} />
            </Link>
          </div>
          <div style={{ padding: '8px 0' }}>
            <Link to={'/themes'}>
              <HeaderButton text={tt('nav-button-themes')} isOn={false} />
            </Link>
          </div>
          <div style={{ padding: '8px 0' }}>
            <Link to={'/resources'}>
              <HeaderButton text={tt('nav-button-resources')} isOn={false} />
            </Link>
          </div>
          <div style={{ padding: '8px 0' }}>
            <Link to={'/about'}>
              <HeaderButton text={tt('nav-button-about')} isOn={false} />
            </Link>
          </div>
          <div style={{ padding: '8px 0' }}>
            <Link to={'/contact'}>
              <HeaderButton text={tt('nav-button-contact')} isOn={false} />
            </Link>
          </div>
        </UIColumn>
      </Menu>
    );
  }

  standardButton(index: number, sectionName: string) {
    const text = Translator.sharedInstance().textForKey(
      `nav-button-${sectionName}`
    );
    return (
      <Link to={`/${sectionName}`} style={{ textDecoration: 'none' }}>
        <HeaderButton
          text={text}
          hasOptions={false}
          isOn={DataModel.sharedInstance().currentSectionIndex === index}
          buttonWasClicked={() => this.setNewSection(index)}
          key={`nav-button-${text}`}
        />
        <UIRowSpace />
      </Link>
    );
  }

  standardButtons() {
    return (
      <>
        {this.standardButton(0, 'search')}
        {this.standardButton(1, 'themes')}
        {this.standardButton(2, 'resources')}
        {this.standardButton(3, 'about')}
        {this.standardButton(4, 'contact')}
      </>
    );
  }

  render() {
    return (
      <NavBarContainer>
        <Breakpoint medium down>
          {this.showConstrained()}
        </Breakpoint>
        <Breakpoint large up>
          <UIRow padding={'13px 0 0 0'}>{this.standardButtons()}</UIRow>
        </Breakpoint>
        <BetweenButtonGroupSpacer />
        <UIRow>
          <LanguageButton
            text={'english'}
            isOn={DataModel.sharedInstance().language === 'english'}
            onClick={() => this.handleLanguageChange('english')}
          />
          <LanguageButton
            text={'māori'}
            isOn={DataModel.sharedInstance().language === 'maori'}
            onClick={() => this.handleLanguageChange('maori')}
          />
        </UIRow>
      </NavBarContainer>
    );
  }
}
