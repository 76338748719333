import { observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';
import { kHeaderButtonHeight, kUIAnimationDuration } from '../lib/constants';
import { Theme } from '../model/theme';

type Props = {
  text: string;
  title?: string;
  onClick?: () => void;
  isOn: boolean;
};

const kLanguageButtonTopHeight = 6;

const ButtonWrapper = styled.button<{ isOn: boolean }>`
  cursor: pointer;
  background-color:${(props) => (props.isOn ? 'white' : 'transparent')};
  color:${(props) => (props.isOn ? Theme.color('accent') : 'white')};
  border:none;
  padding: 24px 8px 12px 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:center;
  height: ${kHeaderButtonHeight + kLanguageButtonTopHeight}px;
  font-size: 15px;
  transition: color,background-color ${kUIAnimationDuration}s linear;
  text-decoration-thickness: 0px;
  &:hover {
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-underline-offset: 3px;
    text-decoration-thickness: 2px;
  }
  &:active {
    background-color: rgba(255,255,255,0.8);
  }
  &:nth-child(1) {
    margin-right: 8px;
  }
}`;

export const LanguageButton = observer((props: Props) => {
  return (
    <ButtonWrapper isOn={props.isOn} onClick={props.onClick}>
      {props.text}
    </ButtonWrapper>
  );
});
