import * as React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { HeaderProps, PageHeader } from './page-header';
import { NavBar } from './nav-bar';
import { NamedColor, Theme } from '../model/theme';

const PageGrid = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  flex: 1;
  background-color: white;
`;

function getContentBackground(
  contentBackgroundColor: string | undefined,
  contentBackgroundImage: string | undefined
) {
  if (contentBackgroundColor) {
    return `background-color:${contentBackgroundColor};`;
  }
  if (contentBackgroundImage) {
    return `background-size: cover;
    background: url('${contentBackgroundImage}') no-repeat center center
    fixed; `;
  }

  return '';
}

const ContentWrapper = styled.div<{
  contentBackgroundColor?: string;
  contentBackgroundImage?: string;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 20px 40px 20px;
  flex: 1;
  ${(props) =>
    getContentBackground(
      props.contentBackgroundColor,
      props.contentBackgroundImage
    )}
`;

/*
html { 
  background: url(images/bg.jpg) no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
*/

export const kPageTopUnsafeAreaHeight = 30;

const ContentUnSafeTopArea = styled.div`
  height: ${kPageTopUnsafeAreaHeight}px;
  width: 100%;
`;

export const kPageNavigationBarHeight = 40;

export const PageContentArea = styled.div`
  flex: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  width: 100%;
`;

const FooterWrapper = styled.div<{ backgroundColor?: NamedColor }>`
  background-color: ${(props) =>
    Theme.color(props.backgroundColor ?? 'transparent')};

  flex: 0 0 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

type PageProps = {
  pageSectionIndex: number;
  headerSpec: HeaderProps;
  content: React.ReactNode;
  contentBackgroundColor?: string;
  contentBackgroundImage?: string;
  footerBackgroundColor?: NamedColor;
  footerTextColor?: NamedColor;
};
@observer
export class Page extends React.Component<PageProps> {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <PageGrid>
        <NavBar selectedIndex={this.props.pageSectionIndex} />
        {this.props.headerSpec ? (
          <PageHeader {...this.props.headerSpec} />
        ) : null}
        <ContentWrapper
          contentBackgroundImage={this.props.contentBackgroundImage}
          contentBackgroundColor={this.props.contentBackgroundColor}
        >
          <ContentUnSafeTopArea></ContentUnSafeTopArea>
          {this.props.content}
        </ContentWrapper>
        <FooterWrapper
          backgroundColor={this.props.footerBackgroundColor}
        ></FooterWrapper>
      </PageGrid>
    );
  }
}
