import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Breakpoint } from 'react-socks';
import styled from 'styled-components';
import { NamedColor } from '../model/theme';
import { tt } from '../model/translator';
import { UIRow } from './layout';
import { NavButton } from './nav-button';
import { StyledText } from './styled-text';

type ThemeNavProps = {
  themeIndex: number;
  numberOfThemes: number;
  backgroundColorName: NamedColor;
  themeWasRequested: (newThemeIndex: number) => void;
};

const ThemeDescriptionWrapper = styled.div<{ isCompact: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: ${(props) => (props.isCompact ? '16px 0' : '20px 100px 30px 30px')};
  max-width: 780px;
  width: 100%;
`;

type ThemeDescriptionProps = {
  isCompact?: boolean;
  description: string | undefined;
  colorName?: NamedColor;
};
export const ThemeDescription = observer((props: ThemeDescriptionProps) => {
  const overrides = props.colorName ? { textColor: props.colorName } : {};
  return (
    <>
      <Breakpoint medium up>
        <ThemeDescriptionWrapper isCompact={false}>
          <StyledText styleName={'theme-description'} overrides={overrides}>
            {props.description ?? ''}
          </StyledText>
        </ThemeDescriptionWrapper>
      </Breakpoint>
      <Breakpoint small down>
        <ThemeDescriptionWrapper isCompact>
          <StyledText styleName={'theme-description'} overrides={overrides}>
            {props.description ?? ''}
          </StyledText>
        </ThemeDescriptionWrapper>
      </Breakpoint>
    </>
  );
});

/*
  ———————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
  Theme nav button      
  ———————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
*/
type ThemeBackButton = {
  textColorName?: NamedColor;
};

export const ThemeNavigationRow = observer((props: ThemeBackButton) => {
  const backText = tt('back-to-themes');

  const textColorName = props.textColorName ?? 'accent';
  const handleClick = () => {};

  return (
    <UIRow align={'flex-start'} style={{ width: '100%' }}>
      <Link to={'/themes'} style={{ textDecoration: 'none' }}>
        <NavButton
          direction={'back'}
          onClick={handleClick}
          text={backText}
          title={backText}
          textColorName={textColorName}
        />
      </Link>
    </UIRow>
  );
});
