import { observer } from 'mobx-react/node_modules/mobx-react-lite';
import { MdArrowBack, MdArrowForward } from 'react-icons/md';
import styled from 'styled-components';
import {
  kUIDisabledOpacity,
  kUShortIAnimationDuration,
} from '../lib/constants';
import { NamedColor, Theme } from '../model/theme';
import { tt } from '../model/translator';
import { RoundDiv } from './round-buttom';
import { StyledText } from './styled-text';

const kDefaultNavButtonSize = 22;

const NavButtonWrapper = styled.button<{ margin?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2px 0;
  background-color: transparent;
  border: 0px;
  margin: ${(props) => props.margin ?? '0'};
  transition: all ${kUShortIAnimationDuration}s linear;
  &:not(:disabled) {
    cursor: pointer;
    &:hover {
      filter: brightness(1.1);
      transform: scale(1.02);
    }
    &:active {
      filter: brightness(1.5);
    }
  }
  &:disabled {
    opacity: ${kUIDisabledOpacity};
  }
`;

type NavButtonProps = {
  direction: 'back' | 'forward';
  size?: number;
  text?: string;
  onClick?: () => void;
  title: string;
  margin?: string;
  textColorName?: NamedColor;
};

export const NavButton = observer((props: NavButtonProps) => {
  const size = props.size ?? kDefaultNavButtonSize;
  const textOverrides = props.textColorName
    ? { textColor: props.textColorName }
    : undefined;
  return (
    <NavButtonWrapper
      onClick={props.onClick}
      title={props.title}
      margin={props.margin}
    >
      {props.direction === 'back' ? (
        <>
          <RoundDiv size={size} style={{ marginRight: '6px' }}>
            <MdArrowBack color={Theme.color('accent')} fontSize={size} />
          </RoundDiv>
          <StyledText styleName={'back-link'} overrides={textOverrides}>
            {props.text ?? tt('back')}
          </StyledText>
        </>
      ) : (
        <>
          <StyledText styleName={'back-link'} overrides={textOverrides}>
            {props.text ?? tt('next')}
          </StyledText>
          <RoundDiv size={size} style={{ marginLeft: '6px' }}>
            <MdArrowForward color={Theme.color('accent')} fontSize={size} />
          </RoundDiv>
        </>
      )}
    </NavButtonWrapper>
  );
});
