type ALStatusType = 'OK' | 'Error' | 'Unknown';

export class ALStatus {
  code: number;
  message: string;
  type: ALStatusType;
  customInfo: string;
  secondCode: number;

  constructor(objectData: any) {
    this.code = objectData.code ?? -1;
    this.message = objectData.message ?? '';
    this.type = objectData.type ?? 'Unknown';
    this.customInfo = objectData.customInfo ?? '';
    this.secondCode = objectData.secondCode ?? -1;
  }

  displayText() {
    const extraInfo = this.customInfo ?? '';
    return `error: ${this.message} (${this.code}) ${extraInfo}`;
  }
}
