import { observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';
import { Book } from '../model/book';
import { ResultSet } from '../model/data-model';
import { APIRequest, RequestResponse } from '../model/request';
import { Theme } from '../model/theme';
import Translator, { tt } from '../model/translator';
import { BookDisplay } from './book-display';
import BookThumbnail from './book-thumbnail';
import { UIColumn } from './layout';
import PaginationControls from './pagination-controls';
import { Spinner } from './spinner';
import { StyledText } from './styled-text';
import { Text } from './text';

type SortByType = 'title' | 'author' | 'date';

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 600px;
  align-items: center;
  justify-content: center;
  border: 1px;
`;

const BrowserWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;

type BookBrowserProps = {
  resultSet: ResultSet | undefined;
  bookFormatter?: (book: Book) => React.ReactNode;
  gridFormatter?: (gridX: number, gridY: number) => React.ReactNode;
  bookWasSelected?: (index: number, book: Book) => void;
};

@observer
export class BookBrowser extends React.Component<BookBrowserProps> {
  /*
    ———————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
    Callbacks
    ———————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
  */

  handleBookSelection(index: number, book: Book) {
    if (this.props.bookWasSelected) {
      this.props.bookWasSelected(index, book);
    }
  }

  /*
    ———————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
    Render
    ———————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
  */

  renderMessage(textMessage: string) {
    return (
      <MessageWrapper>
        <Text relativeSize={6} textColor={'accent'} weight={'light'}>
          {textMessage}
        </Text>
      </MessageWrapper>
    );
  }

  renderNoResults() {
    const message = tt('search-no-books-found');
    return this.renderMessage(message);
  }

  renderError() {
    const message = tt('load-books-error');
    return this.renderMessage(message);
  }

  renderLoading() {
    const message = tt('searching-for-books');
    return (
      <MessageWrapper>
        <Spinner sizeInPixels={128} />
        <Text
          relativeSize={3}
          textColor={'accent'}
          weight={'light'}
          spaceBefore={8}
        >
          {message}
        </Text>
      </MessageWrapper>
    );
  }

  renderBooks() {
    const imageHeight = 200;
    return (
      <UIColumn align={'center'}>
        <UIColumn align={'center'}>
          <BrowserWrapper>
            {this.props.resultSet
              ? this.props.resultSet.books.map((book: Book, index) => (
                  <UIColumn key={`book-search-${book.id}`}>
                    <BookThumbnail
                      url={book.thumbnailURL(imageHeight)}
                      title={book.title_eng}
                      heightInPixels={imageHeight}
                      onClick={() => this.handleBookSelection(index, book)}
                      margin={'2px'}
                    />
                  </UIColumn>
                ))
              : null}
          </BrowserWrapper>
        </UIColumn>
      </UIColumn>
    );
  }

  renderResultSet() {
    if (!this.props.resultSet) {
      return this.renderError();
    }
    if (this.props.resultSet.numberOfBooks === 0) {
      return this.renderNoResults();
    } else {
      return this.renderBooks();
    }
  }

  render() {
    if (!this.props.resultSet) {
      return this.renderLoading();
    }
    const resultSet = this.props.resultSet;
    if (resultSet.errorStatus !== 0) {
      return this.renderError();
    }
    if (this.props.resultSet.numberOfBooks === 0) {
      return this.renderNoResults();
    }
    return this.renderBooks();
  }
}
