import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';
import { UIColumn, UIRow } from '../../components/layout';
import { NavButton } from '../../components/nav-button';
import { StyledText } from '../../components/styled-text';
import { contentForHeaderImage } from '../../layout/page-header';
import { Page } from '../../layout/page.';
import { Collection } from '../../model/collection';
import DataModel, { CollectionResultSet } from '../../model/data-model';
import { BookTheme } from '../../model/interfaces';
import { NamedColor, Theme } from '../../model/theme';
import { tt } from '../../model/translator';
import { useCurrentWidth } from 'react-socks';
import { Book } from '../../model/book';
import BookThumbnail, {
  imageThumbnailWidthWithHeight,
} from '../../components/book-thumbnail';
import { BookDisplay } from '../../components/book-display';
import { timeStamp } from 'console';
import { Link } from 'react-router-dom';
import {
  ThemeDescription,
  ThemeNavigationRow,
} from '../../components/theme-components';
import { BookBrowser } from '../../components/book-browser';

type TeAoHurihuriThemePageState = {
  collection: Collection | undefined;
  bookToDisplay: Book | undefined;
};
@observer
export class TeAoHurihuriThemePage extends React.Component<
  {},
  TeAoHurihuriThemePageState
> {
  private _collectionID: number;

  constructor(props: {}) {
    super(props);
    this._collectionID = 6;
    this.state = { collection: undefined, bookToDisplay: undefined };
  }

  componentDidMount() {
    const collection = DataModel.sharedInstance().getCachedCollectionWithID(
      this._collectionID
    );
    this.setState({ collection: collection });
  }

  showBook(book: Book, index: number) {
    this.setState({ bookToDisplay: book });
  }

  clearBook() {
    this.setState({ bookToDisplay: undefined });
  }

  goBack() {}

  getBookGrid() {
    const bookHeight = 300;
    const books = this.state.collection?.books ?? [];
    return (
      <UIRow
        style={{
          flexWrap: 'wrap',
          maxWidth: '1500px',
          backgroundColor: Theme.color('orange'),
          padding: '10px',
        }}
      >
        {books.map((book: Book, index) => {
          return (
            <BookThumbnail
              title={book.languageAwareTitle()}
              url={book.thumbnailWithHeightURL(bookHeight)}
              onClick={() => this.showBook(book, index)}
              heightInPixels={bookHeight}
              key={`book-${book.id}`}
            />
          );
        })}
      </UIRow>
    );
  }

  getContent() {
    return (
      <UIColumn>
        <UIColumn padding={'20px'}>
          {this.state.bookToDisplay ? (
            <BookDisplay
              book={this.state.bookToDisplay}
              backButtonWasClicked={() => this.clearBook()}
            />
          ) : (
            <>
              <ThemeNavigationRow />
              <ThemeDescription
                description={this.state.collection?.description_eng}
              />
              {this.getBookGrid()}
            </>
          )}
        </UIColumn>
      </UIColumn>
    );
  }

  render() {
    return (
      <Page
        pageSectionIndex={2}
        headerSpec={{
          backgroundColor: 'theme-te-ao-hurihuri',
          customContent: contentForHeaderImage(
            '/images/theme/te-ao-hurihuri/header.png'
          ),

          letterImageLetter: 'a',
        }}
        content={this.getContent()}
        footerBackgroundColor={'theme-te-ao-hurihuri'}
        footerTextColor={'white'}
      />
    );
  }
}
