import * as React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { Book } from '../model/book';
import { NavButton } from './nav-button';
import { tt } from '../model/translator';
import { StyledText } from './styled-text';
import { DateTime } from 'luxon';
import { UIColumn, UIRow } from './layout';
import BookThumbnail from './book-thumbnail';
import { Theme } from '../model/theme';
import { Breakpoint } from 'react-socks';

const BookDisplayWrapper = styled.div<{ backgroundColor: string }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  padding: 20px 0;
  border-radius: 8px;
  background-color: ${(props) => props.backgroundColor};
`;

const NavWrapper = styled.div`
  /* flex: 1; */
  width: 30px;
  padding: 0 8px 0 0;
`;

const ContentWrapper = styled.div`
  flex: 10;
  max-width: 1000px;
  padding: 0 8px 0 8px;
`;

const MediaWrapper = styled.div`
  /* flex: 3; */
  padding: 0 8px 0 8px;
  width: 5px;
`;

type BookDisplayProps = {
  book: Book;
  backButtonWasClicked: () => void;
  backgroundColor?: string;
};

type BookDisplayState = {};
@observer
export class BookDisplay extends React.Component<
  BookDisplayProps,
  BookDisplayState
> {
  private _isMounted: boolean;

  constructor(props: BookDisplayProps) {
    super(props);
    this._isMounted = true;
  }

  goBack() {
    if (this.props.backButtonWasClicked) {
      this.props.backButtonWasClicked();
    }
  }

  getStandardBookBasicData() {
    const book = this.props.book;
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <StyledText styleName={'book-title'} title={`${book.id}`}>
          {book.languageAwareTitle()}
        </StyledText>
        <StyledText styleName={'Title3'}>{book.author}</StyledText>
        <StyledText styleName={'Text2'}>
          {book.created_at?.toLocaleString(DateTime.DATE_MED)}
        </StyledText>
      </div>
    );
  }

  getStandardImageHeader() {
    return (
      <UIRow vAlign='flex-start'>
        <BookThumbnail
          url={this.props.book.thumbnailWithHeightURL(500)}
          heightInPixels={500}
          title={''}
          margin={'none'}
        />
        <div
          style={{
            backgroundColor: Theme.color('bookBanner'),
            padding: '32px',
            flex: 1,
          }}
        >
          {this.props.book
            .getIntroductionParagraphs()
            .map((s: string, index) => (
              <StyledText
                key={`i-${index}`}
                styleName={'Text4'}
                overrides={{ textColor: 'white' }}
              >
                {s}.
              </StyledText>
            ))}
        </div>
      </UIRow>
    );
  }

  getCompactImageHeader() {
    const imageHeight = 350;
    return (
      <UIColumn
        vAlign='center'
        style={{
          backgroundColor: Theme.color('bookBanner'),
          padding: '32px',
          flex: 1,
        }}
      >
        {this.props.book.getIntroductionParagraphs().map((s: string, index) => (
          <StyledText
            key={`i-${index}`}
            styleName={'Text4'}
            overrides={{ textColor: 'white' }}
          >
            {s}.
          </StyledText>
        ))}
        <BookThumbnail
          url={this.props.book.thumbnailWithHeightURL(imageHeight)}
          heightInPixels={imageHeight}
          title={''}
          margin={'10px 0 0 0'}
          useDropShadow={false}
        />
      </UIColumn>
    );
  }

  getStandardBookInfo() {
    return (
      <UIColumn align={'flex-start'}>
        <StyledText styleName={'Title2'} overrides={{ spaceBefore: 40 }}>
          Publication details
        </StyledText>

        {this.props.book
          .getPublicationInfoParagraphs()
          .map((s: string, index) => (
            <StyledText key={`p-${index}`} styleName={'Text2'}>
              {s}
            </StyledText>
          ))}
        <StyledText styleName={'Title2'} overrides={{ spaceBefore: 12 }}>
          Description
        </StyledText>
        {this.props.book.getDescriptionParagraphs().map((s: string, index) => (
          <StyledText key={`d-${index}`} styleName={'Text2'}>
            {s}.
          </StyledText>
        ))}
        <StyledText styleName={'Title2'} overrides={{ spaceBefore: 12 }}>
          Availability
        </StyledText>
        <StyledText styleName={'Text2'}>
          <div
            dangerouslySetInnerHTML={{ __html: this.props.book.availability }}
          />
        </StyledText>
      </UIColumn>
    );
  }

  componentDidMount() {
    this._isMounted = true;
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const backText = tt('nav-back');
    const bgColor = this.props.backgroundColor ?? 'transparent';
    const backButton = (
      <NavButton
        direction={'back'}
        onClick={() => this.goBack()}
        text={backText}
        title={backText}
        margin={'0 0 16px -28px'}
      />
    );
    return (
      <BookDisplayWrapper backgroundColor={bgColor}>
        <NavWrapper></NavWrapper>
        <ContentWrapper>
          <Breakpoint medium up>
            {backButton}
            {this.getStandardBookBasicData()}
            {this.getStandardImageHeader()}
            {this.getStandardBookInfo()}
          </Breakpoint>
          <Breakpoint small down>
            {backButton}
            {this.getStandardBookBasicData()}
            {this.getCompactImageHeader()}
            {this.getStandardBookInfo()}
          </Breakpoint>
        </ContentWrapper>
        <MediaWrapper></MediaWrapper>
      </BookDisplayWrapper>
    );
  }
}
