import * as React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { NamedColor, Theme } from '../model/theme';
import {
  kHeaderHeight,
  kHeaderNavBarHeight,
  kNavBarAfterSpace,
} from '../lib/constants';
import { Breakpoint } from 'react-socks';

export function contentForHeaderImage(imageSrc: string): React.ReactNode {
  return (
    <>
      <Breakpoint medium up>
        <img
          src={imageSrc}
          alt={'Te Takarangi Banner'}
          style={{ height: '100px' }}
        />
      </Breakpoint>
      <Breakpoint small down>
        <img
          src={imageSrc}
          alt={'Te Takarangi Banner'}
          style={{ height: '70px' }}
        />
      </Breakpoint>
    </>
  );
}

type MastheadLetter = 't' | 'a' | 'm' | 'r' | undefined;

const HeaderContainer = styled.div<{ backgroundColor: string }>`
  background-color: ${(props) => props.backgroundColor};
  height: ${kHeaderHeight}px;
  max-height: ${kHeaderHeight}px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const kMastheadLetterImageHeight = 180;

const MastheadContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: flex-start;
  padding-left: 8px;
`;

const TitlesContainer = styled.div`
  flex: 6;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 8px;
`;

const MastheadTitle = styled.div`
  font-size: 48px;
  flex: 1;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 8px;
  color: white;
`;

const MastheadSubtitle = styled.div`
  font-size: 22px;
  flex: 1;
  align-items: flex-start;
  justify-content: center;
  color: white;
`;

const MastheadRightSpacer = styled.div`
  flex: 4;
`;

const MastheadImageContainer = styled.div`
  position: absolute;
  top: ${kHeaderNavBarHeight + kNavBarAfterSpace}px;
  right: 0px;
  height: ${kMastheadLetterImageHeight}px;
  max-height: ${kMastheadLetterImageHeight}px;
`;

const MastheadImage = styled.img`
  height: ${kMastheadLetterImageHeight}px;
`;

export type HeaderProps = {
  backgroundColor?: NamedColor;
  letterImageLetter?: MastheadLetter;
  title?: string;
  subtitle?: string;
  customContent?: React.ReactNode;
};

export const PageHeader = observer((props: HeaderProps) => {
  const letterImageOrNull = props.letterImageLetter ? (
    <MastheadImageContainer>
      {props.letterImageLetter !== undefined ? (
        <MastheadImage
          src={`/images/letters/${props.letterImageLetter}.png`}
          alt={'Masthead image'}
        />
      ) : null}
    </MastheadImageContainer>
  ) : null;

  const bgColorName = props.backgroundColor ?? 'transparent';
  const titleContent = props.customContent ?? (
    <>
      <MastheadTitle>{props.title}</MastheadTitle>
      {props.subtitle ? (
        <MastheadSubtitle>{props.subtitle}</MastheadSubtitle>
      ) : null}
    </>
  );

  const backgroundColor = Theme.color(bgColorName);
  return (
    <HeaderContainer backgroundColor={backgroundColor}>
      <MastheadContainer>
        <TitlesContainer>{titleContent}</TitlesContainer>
        <MastheadRightSpacer />
      </MastheadContainer>
      <Breakpoint medium up>
        {letterImageOrNull}
      </Breakpoint>
    </HeaderContainer>
  );
});
