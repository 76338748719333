import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { UIColumn } from '../components/layout';
import { NavButton } from '../components/nav-button';
import { StyledText } from '../components/styled-text';
import { Page } from '../layout/page.';
import { Theme } from '../model/theme';
import { tt } from '../model/translator';

const Background = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-size: cover;
  background: url('/images/404-bck.png');
  z-index: 0;
`;
@observer
export class Error404 extends React.Component {
  render() {
    const text = tt('404-continue');
    return (
      <UIColumn align={'center'} vAlign={'center'} style={{ height: '100%' }}>
        <Background />
        <div style={{ zIndex: '1000' }}>
          <img
            src='/images/te_takarangi_white.png'
            style={{ height: '100px', marginBottom: '30px' }}
            alt={''}
          />
          <StyledText
            styleName='Title4'
            overrides={{ weight: 'bold', textColor: 'white' }}
          >
            Sorry, we can't find that web page
          </StyledText>
          <div style={{ marginTop: '16px' }}>
            <Link to='/search' style={{ textDecoration: 'none' }}>
              <NavButton
                direction='back'
                title={text}
                textColorName={'white'}
                text={text}
              />
            </Link>
          </div>
        </div>
      </UIColumn>
    );
  }
}
