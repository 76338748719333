import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';
import { UIColumn, UIRow } from '../../components/layout';
import { NavButton } from '../../components/nav-button';
import { StyledText } from '../../components/styled-text';
import { contentForHeaderImage } from '../../layout/page-header';
import { Page } from '../../layout/page.';
import { Collection } from '../../model/collection';
import DataModel, { CollectionResultSet } from '../../model/data-model';
import { BookTheme } from '../../model/interfaces';
import { NamedColor, Theme } from '../../model/theme';
import { tt } from '../../model/translator';
import { Breakpoint, useCurrentWidth } from 'react-socks';
import { Book } from '../../model/book';
import BookThumbnail, {
  imageThumbnailWidthWithHeight,
} from '../../components/book-thumbnail';
import { BookDisplay } from '../../components/book-display';
import { timeStamp } from 'console';
import { Link } from 'react-router-dom';
import { ThemeDescription } from '../../components/theme-components';

const coordinates = [
  { x: 30, y: 290 },
  { x: 300, y: 280 },
  { x: 500, y: 233 },
  { x: 33, y: 11 },
  { x: 190, y: 100 },
  { x: 390, y: 40 },
  { x: 650, y: 300 },
  { x: 120, y: 470 },
  { x: 600, y: 20 },
];

// .shimmer {
//   display: inline-block;
//   color:grey;
//   background: #acacac -webkit-gradient(linear, 100% 0, 0 0, from(#acacac), color-stop(0.5, #ffffff), to(#acacac));
//   background-position: -50rem top; /*50px*/
//   background-repeat: no-repeat;
//   -webkit-background-clip: text;
//   -webkit-text-fill-color: transparent;
//   -webkit-animation-name: shimmer;
//   -webkit-animation-duration: 2.2s;
//   -webkit-animation-iteration-count: infinite;
//   -webkit-background-size: 50rem 100%; /*50px*/
//   font-size: 90px;
// }

// @-webkit-keyframes shimmer {
//     0% {
//         background-position: -50rem top; /*50px*/
//     }
//     70% {
//         background-position: 12.5rem top; /*200px*/
//     }
//     100% {
//         background-position: 12.5rem top; /*200px*/
//     }
// }

const kConstellationHeight = 500;
const kStarHeight = 160;
const kStarBannerWidth = 300;

const ConstellationWrapper = styled.div<{ width: number }>`
  position: relative;
  width: ${(props) => props.width}px;
  height: ${kConstellationHeight}px;
`;

const Star = styled.div<{ x: number; y: number }>`
  position: absolute;
  left: ${(props) => props.x}px;
  top: ${(props) => props.y}px;
`;

const StarBannerContainer = styled.div<{ x: number; y: number }>`
  position: absolute;
  left: ${(props) => props.x}px;
  top: ${(props) => props.y}px;
  width: ${kStarBannerWidth}px;
  background-color: ${(props) => Theme.color('white', 0.9)};
  border-radius: 12px;
  border: 1px solid ${(props) => Theme.color('white', 0.2)};
  padding: 16px;
`;

function bannerPositionForIndex(index: number): { x: number; y: number } {
  const kStarBannerOffset = 20;
  const starWidth = imageThumbnailWidthWithHeight(kStarHeight);

  const starLeftEdge = coordinates[index].x;
  const starRightEdge = coordinates[index].x + starWidth;

  const starBottom = coordinates[index].y + kStarHeight + kStarBannerOffset * 2;
  return {
    x: starLeftEdge > 400 ? starRightEdge - kStarBannerWidth : starLeftEdge,
    y: starBottom,
  };
}

type StarInfo = {
  index: number;
  book: Book;
};

const StarBanner = observer((props: StarInfo) => {
  return (
    <StarBannerContainer
      x={bannerPositionForIndex(props.index).x}
      y={bannerPositionForIndex(props.index).y}
    >
      {props.book.getIntroductionParagraphs().map((s: string) => (
        <StyledText key={`paragraph-${s}`} styleName={'Text2'}>
          {s}
        </StyledText>
      ))}
    </StarBannerContainer>
  );
});

type ConstellationProps = {
  books: Book[] | undefined;
  bookWasClicked?: (book: Book, index: number) => void;
};

const Constellation = observer((props: ConstellationProps) => {
  const width = useCurrentWidth();

  const booksArray = props.books ?? [];

  const bookWasClicked = (book: Book, index: number) => {
    if (props.bookWasClicked) {
      props.bookWasClicked(book, index);
    }
  };

  const [hoveredBook, setHoveredBook] = React.useState<StarInfo | undefined>(
    undefined
  );

  return (
    <UIColumn align={'center'}>
      <ConstellationWrapper width={800}>
        {booksArray.map((book: Book, index) => {
          return (
            <Star
              key={`star-${book.id}`}
              x={coordinates[index].x}
              y={coordinates[index].y}
              onMouseEnter={(e) => setHoveredBook({ index: index, book: book })}
              onMouseLeave={(e) => setHoveredBook(undefined)}
            >
              <BookThumbnail
                title={`${index}`}
                url={book.thumbnailWithHeightURL(kStarHeight)}
                heightInPixels={kStarHeight}
                onClick={() => bookWasClicked(book, index)}
              />
            </Star>
          );
        })}
        {/* {hoveredBook ? <StarBanner {...hoveredBook} /> : null} */}
      </ConstellationWrapper>
    </UIColumn>
  );
});

type MatarikiThemePageState = {
  collection: Collection | undefined;
  bookToDisplay: Book | undefined;
};
@observer
export class MatarikiThemePage extends React.Component<
  {},
  MatarikiThemePageState
> {
  private _collectionID: number;

  constructor(props: {}) {
    super(props);
    this._collectionID = 2;
    this.state = { collection: undefined, bookToDisplay: undefined };
  }

  collectionIsLoaded(collection: Collection | undefined) {
    this.setState({ collection: collection });
  }

  componentDidMount() {
    const collection = DataModel.sharedInstance().getCollectionWithID(
      this._collectionID,
      (collection: Collection | undefined) =>
        this.collectionIsLoaded(collection)
    );
    if (collection) {
      this.collectionIsLoaded(collection);
    }
  }

  showBook(book: Book, index: number) {
    this.setState({ bookToDisplay: book });
  }

  clearBook() {
    this.setState({ bookToDisplay: undefined });
  }

  goBack() {}

  getBackButton() {
    const backText = tt('back-to-themes');
    return (
      <UIRow align={'flex-start'} style={{ width: '100%' }}>
        <Link to={'/themes'} style={{ textDecoration: 'none' }}>
          <NavButton
            direction={'back'}
            onClick={() => this.goBack()}
            text={backText}
            title={backText}
            textColorName={'white'}
          />
        </Link>
      </UIRow>
    );
  }

  getCompactContent() {
    const books = this.state.collection?.books ?? [];
    return (
      <UIColumn>
        {this.state.bookToDisplay ? (
          <BookDisplay
            book={this.state.bookToDisplay}
            backButtonWasClicked={() => this.clearBook()}
            backgroundColor={Theme.color('white', 0.7)}
          />
        ) : (
          <>
            {this.getBackButton()}
            <ThemeDescription
              isCompact={true}
              description={this.state.collection?.description_eng}
              colorName={'white'}
            />
            <UIRow style={{ width: '100%', padding: '6px', flexWrap: 'wrap' }}>
              {books.map((book: Book, index) => {
                return (
                  <BookThumbnail
                    title={''}
                    heightInPixels={180}
                    url={book.thumbnailWithHeightURL(180)}
                    onClick={() => this.showBook(book, index)}
                    key={`book-${book.id}`}
                  />
                );
              })}
            </UIRow>
          </>
        )}
      </UIColumn>
    );
  }

  getStandardContent() {
    const books = this.state.collection?.books ?? [];
    return (
      <UIColumn>
        {this.state.bookToDisplay ? (
          <BookDisplay
            book={this.state.bookToDisplay}
            backButtonWasClicked={() => this.clearBook()}
            backgroundColor={Theme.color('white', 0.7)}
          />
        ) : (
          <UIColumn vAlign={'flex-start'} style={{ minHeight: '900px' }}>
            {this.getBackButton()}
            <ThemeDescription
              isCompact={false}
              description={this.state.collection?.description_eng}
              colorName={'white'}
            />
            <Constellation
              books={books}
              bookWasClicked={(book: Book, index: number) =>
                this.showBook(book, index)
              }
            />
          </UIColumn>
        )}
      </UIColumn>
    );
  }

  getContent() {
    return (
      <>
        <Breakpoint medium up>
          {this.getStandardContent()}
        </Breakpoint>
        <Breakpoint small down>
          {this.getCompactContent()}
        </Breakpoint>
      </>
    );
  }

  render() {
    return (
      <Page
        pageSectionIndex={2}
        headerSpec={{
          customContent: contentForHeaderImage(
            '/images/theme/matariki/header.png'
          ),
          backgroundColor: 'accent',
          letterImageLetter: 'm',
        }}
        content={this.getContent()}
        contentBackgroundImage={'/images/theme/matariki/bck.jpg'}
        contentBackgroundColor={'black'}
        footerBackgroundColor={'accent'}
        footerTextColor={'white'}
      />
    );
  }
}
