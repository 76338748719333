import { observer } from 'mobx-react';
import React from 'react';
import { scaleDown } from 'react-burger-menu';
import { Breakpoint } from 'react-socks';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
import { BookBrowser } from '../components/book-browser';
import { BookDisplay } from '../components/book-display';
import { UIColumn, UIFlexSpace, UIRow, UIRowSpace } from '../components/layout';
import { NavButton } from '../components/nav-button';
import { PageNavigationBar } from '../components/page-navigation-bar';
import PaginationControls from '../components/pagination-controls';
import { SearchField } from '../components/search-field';
import { StyledText } from '../components/styled-text';
import { Page } from '../layout/page.';
import { Book } from '../model/book';
import DataModel, { CollectionResultSet, ResultSet } from '../model/data-model';
import { Theme } from '../model/theme';
import { tt } from '../model/translator';

type SearchPageProps = {};

type SearchPageState = {
  searchTerm: string;
  resultSet: ResultSet | undefined;
  bookToDisplay: Book | undefined;
  isWaitingForData: boolean;
};

const HidableContent = styled.div<{ isVisible: boolean }>`
  transition: opacity 0.4s linear;
  transform: ${(props) => (props.isVisible ? 'scale(1)' : 'scale(0)')};
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  ${(props) => (props.isVisible ? null : 'height: 0px')};
  width: 100%;
`;

@observer
export class SearchPage extends React.Component<
  SearchPageProps,
  SearchPageState
> {
  private _numberOfPages: number;
  private _pageIndex: number;
  private _filterValueTimeout: number;

  constructor(props: SearchPageProps) {
    super(props);
    this.state = {
      searchTerm: '',
      bookToDisplay: undefined,
      resultSet: undefined,
      isWaitingForData: false,
    };
    this._numberOfPages = 0;
    this._pageIndex = 0;
    this._filterValueTimeout = -1;

    DataModel.sharedInstance().loadCollections(
      (collections: CollectionResultSet) => {}
    );
  }
  /*
    ————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
    Callbacks from Book browser
    ————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
  */

  bookWasSelected(index: number, book: Book) {
    this.setState({ bookToDisplay: book });
  }

  /*
    ————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
    Handle search term
    ————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
  */

  searchIsActive() {
    return this.state.searchTerm !== '';
  }

  resetToNewSearch() {
    this.setState({ searchTerm: '' });
  }

  cancelBookDisplay() {
    this.setState({ bookToDisplay: undefined });
  }

  /*
    ————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
    Display a specific book
    ————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
  */
  contentForBook() {
    return this.state.bookToDisplay ? (
      <BookDisplay
        book={this.state.bookToDisplay}
        backButtonWasClicked={() => this.cancelBookDisplay()}
        key={`book-${this.state.bookToDisplay.id}`}
      />
    ) : null;
  }

  /*
    ————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
    Data load callback
    ————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
  */

  dataHasLoaded(resultSet: ResultSet) {
    this._numberOfPages = resultSet.numberOfPages;
    this._pageIndex = resultSet.pageIndex;
    this.setState({ resultSet: resultSet, isWaitingForData: false });
  }

  /*
    ————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
    Loading data
    ————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
  */

  loadPageWithIndex(pageIndex: number) {
    console.log(
      'loadPageWithIndex',
      DataModel.sharedInstance().getBooksForPage(pageIndex)
    );
    DataModel.sharedInstance().performBookSearch(
      this.state.searchTerm,
      25,
      pageIndex,
      (resultSet: ResultSet) => this.dataHasLoaded(resultSet)
    );
    this.setState({ isWaitingForData: true, resultSet: undefined });
  }

  paginationControlSelectedPage(newPageIndex: number) {
    this.loadPageWithIndex(newPageIndex);
  }

  /*
    ————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
    Handle search 
    ————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
  */

  doFilteredSearch() {
    DataModel.sharedInstance().performBookSearch(
      this.state.searchTerm,
      25,
      0,
      (resultSet: ResultSet) => this.dataHasLoaded(resultSet)
    );
    this.setState({ isWaitingForData: true, resultSet: undefined });
  }

  searchTermDidChange(newValue: string) {
    this.setState({ searchTerm: newValue });
    if (this._filterValueTimeout !== -1) {
      clearTimeout(this._filterValueTimeout);
      this._filterValueTimeout = -1;
    }
    this._filterValueTimeout = window.setTimeout(() => {
      this.doFilteredSearch();
    }, 500);
  }

  searchTermWasReset() {
    if (this._filterValueTimeout !== -1) {
      clearTimeout(this._filterValueTimeout);
      this._filterValueTimeout = -1;
    }
    this.setState({ searchTerm: '' }, () => this.loadPageWithIndex(0));
  }

  compactSearchAndPagination() {
    const placeholderText = tt('search-term-placeholder');
    return (
      <UIColumn vAlign={'center'} align={'center'}>
        <SearchField
          value={this.state.searchTerm}
          placeholder={placeholderText}
          valueDidChange={(newTerm: string) =>
            this.searchTermDidChange(newTerm)
          }
          clearbuttonwasclicked={() => this.searchTermWasReset()}
        />
        <br />

        <PaginationControls
          pageIndex={this._pageIndex}
          numberOfPages={this._numberOfPages}
          controlIsDisabled={this.state.isWaitingForData}
          pageWasSelected={(newPageIndex: number) =>
            this.paginationControlSelectedPage(newPageIndex)
          }
        />
      </UIColumn>
    );
  }

  standardSearchAndPagination() {
    const placeholderText = tt('search-term-placeholder');
    return (
      <UIRow vAlign={'center'} align={'center'}>
        <SearchField
          value={this.state.searchTerm}
          placeholder={placeholderText}
          valueDidChange={(newTerm: string) =>
            this.searchTermDidChange(newTerm)
          }
          clearbuttonwasclicked={() => this.searchTermWasReset()}
        />
        <UIFlexSpace />
        <PaginationControls
          pageIndex={this._pageIndex}
          numberOfPages={this._numberOfPages}
          controlIsDisabled={this.state.isWaitingForData}
          pageWasSelected={(newPageIndex: number) =>
            this.paginationControlSelectedPage(newPageIndex)
          }
        />
      </UIRow>
    );
  }

  searchAndPaginationContent() {
    return (
      <>
        <Breakpoint medium up>
          {this.standardSearchAndPagination()}
        </Breakpoint>
        <Breakpoint small down>
          {this.compactSearchAndPagination()}
        </Breakpoint>
      </>
    );
  }

  /*
    ————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
    Render content
    ————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
  */

  getRenderedContent() {
    const bookToDisplay = this.state.bookToDisplay;

    const browserContent = (
      <>
        {this.searchAndPaginationContent()}
        <BookBrowser
          resultSet={this.state.resultSet}
          bookWasSelected={(index: number, book: Book) =>
            this.bookWasSelected(index, book)
          }
        />
      </>
    );
    return (
      <UIColumn style={{ width: '100%', position: 'relative' }}>
        <HidableContent isVisible={bookToDisplay === undefined}>
          {browserContent}
        </HidableContent>
        <HidableContent isVisible={bookToDisplay !== undefined}>
          {this.contentForBook()}
        </HidableContent>
      </UIColumn>
    );
  }

  render() {
    const headerContent = (
      <img
        src={'/images/te_takarangi_white.png'}
        alt={'Te Takarangi Banner'}
        style={{ height: '100px' }}
      />
    );

    return (
      <Page
        pageSectionIndex={0}
        headerSpec={{
          customContent: headerContent,
          backgroundColor: 'accent',
          letterImageLetter: 't',
        }}
        content={this.getRenderedContent()}
        footerBackgroundColor={'accent'}
        footerTextColor={'white'}
      />
    );
  }

  /*
    ————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
    Initial Render - kick off a load of the entire collection
    ————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
  */

  componentDidMount() {
    // Kick off a request for the book loading
    this.loadPageWithIndex(0);
  }
}
