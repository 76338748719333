import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';
import { UIColumn, UIRow } from '../../components/layout';
import { NavButton } from '../../components/nav-button';
import { StyledText } from '../../components/styled-text';
import { contentForHeaderImage } from '../../layout/page-header';
import { Page } from '../../layout/page.';
import { Collection } from '../../model/collection';
import DataModel, { CollectionResultSet } from '../../model/data-model';
import { BookTheme } from '../../model/interfaces';
import { NamedColor, Theme } from '../../model/theme';
import { tt } from '../../model/translator';
import { Breakpoint, useCurrentWidth } from 'react-socks';
import { Book } from '../../model/book';
import BookThumbnail, {
  imageThumbnailWidthWithHeight,
} from '../../components/book-thumbnail';
import { BookDisplay } from '../../components/book-display';
import { timeStamp } from 'console';
import { Link } from 'react-router-dom';
import {
  ThemeDescription,
  ThemeNavigationRow,
} from '../../components/theme-components';
import { BookBrowser } from '../../components/book-browser';

type TeReoRangatiraThemePageState = {
  collection: Collection | undefined;
  bookToDisplay: Book | undefined;
};
@observer
export class TeReoRangatiraThemePage extends React.Component<
  {},
  TeReoRangatiraThemePageState
> {
  private _collectionID: number;

  constructor(props: {}) {
    super(props);
    this._collectionID = 4;
    this.state = { collection: undefined, bookToDisplay: undefined };
  }

  componentDidMount() {
    const collection = DataModel.sharedInstance().getCachedCollectionWithID(
      this._collectionID
    );
    this.setState({ collection: collection });
  }

  reOrderedBooks() {
    if (this.state.collection) {
      const reordered = [];
      const existing = this.state.collection.books;
      reordered.push(existing[0]);
      reordered.push(existing[1]);
      reordered.push(existing[3]);
      reordered.push(existing[2]);
      reordered.push(existing[4]);
      reordered.push(existing[5]);
      reordered.push(existing[7]);
      reordered.push(existing[6]);
      reordered.push(existing[8]);
      reordered.push(existing[11]);
      reordered.push(existing[9]);
      reordered.push(existing[10]);
      return reordered;
    }
  }

  showBook(book: Book, index: number) {
    this.setState({ bookToDisplay: book });
  }

  clearBook() {
    this.setState({ bookToDisplay: undefined });
  }

  goBack() {}

  getContent() {
    const books = this.state.collection?.books ?? [];
    const bookHeight = 240;
    return (
      <UIColumn>
        <UIColumn padding={'20px'}>
          {this.state.bookToDisplay ? (
            <BookDisplay
              book={this.state.bookToDisplay}
              backButtonWasClicked={() => this.clearBook()}
            />
          ) : (
            <>
              <ThemeNavigationRow />
              <ThemeDescription
                description={this.state.collection?.description_eng}
              />
              <UIRow style={{ flexWrap: 'wrap' }}>
                {books.map((book: Book, index) => {
                  return (
                    <BookThumbnail
                      title={book.languageAwareTitle()}
                      url={book.thumbnailWithHeightURL(bookHeight)}
                      onClick={() => this.showBook(book, index)}
                      heightInPixels={bookHeight}
                      key={`book-${book.id}`}
                    />
                  );
                })}
              </UIRow>
            </>
          )}
        </UIColumn>
      </UIColumn>
    );
  }

  render() {
    return (
      <Page
        pageSectionIndex={2}
        headerSpec={{
          backgroundColor: 'accent',
          customContent: contentForHeaderImage(
            '/images/theme/te-reo-rangatira/header.png'
          ),
          letterImageLetter: 'r',
        }}
        content={this.getContent()}
        footerBackgroundColor={'accent'}
        footerTextColor={'white'}
      />
    );
  }
}
