import { observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';
import { Theme } from '../model/theme';
import { MdClear, MdSearch } from 'react-icons/md';

const kSearchFieldHeight = 35;
const kIconFontSize = 18;
const kSearchFieldFontSize = 16;

type SearchFieldProps = {
  value: string;
  placeholder?: string;
  initialValue?: string;
  clearbuttonwasclicked?: () => void;
  valueDidChange?: (newValue: string) => void;
  enterKeyWasPressed?: () => void;
};

const Wrapper = styled.div`
  border: 0;
  display: flex;
  flex-direction: row;
  position: relative;
`;

const StyledInput = styled.input`
  position: relative;
  height: ${kSearchFieldHeight}px;
  border: 1px solid ${() => Theme.color('accent')};
  border-radius: 100px;
  padding: 2px 28px 2px 25px;
  font-size: ${kSearchFieldFontSize}px;
`;

const ClearButton = styled.button`
  border: 0px;
  background-color: transparent;
  position: absolute;
  top: 8px;
  right: 4px;
  cursor: pointer;
  &:hover {
    filter: brightness(0.8);
  }
`;

const SearchIcon = styled.div`
  border: 0px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 12px;
  left: 8px;
`;

@observer
export class SearchField extends React.Component<SearchFieldProps> {
  clearButtonClicked() {
    if (this.props.clearbuttonwasclicked) {
      this.props.clearbuttonwasclicked();
    } else if (this.props.valueDidChange) {
      this.props.valueDidChange('');
    }
  }

  valueDidChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (this.props.valueDidChange) {
      this.props.valueDidChange(e.currentTarget.value);
    }
  }

  handleKey(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      if (this.props.enterKeyWasPressed) {
        this.props.enterKeyWasPressed();
      }
    }
    if (e.key === 'Escape') {
      this.clearButtonClicked();
    }
  }

  render() {
    const clearButtonOrNull =
      this.props.value !== '' ? (
        <ClearButton onClick={() => this.clearButtonClicked()}>
          <MdClear fontSize={kIconFontSize} color={Theme.color('accent')} />
        </ClearButton>
      ) : null;

    return (
      <Wrapper>
        <StyledInput
          placeholder={this.props.placeholder}
          value={this.props.value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            this.valueDidChange(e)
          }
          onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) =>
            this.handleKey(e)
          }
        />
        <SearchIcon>
          <MdSearch fontSize={kIconFontSize} color={Theme.color('40%')} />
        </SearchIcon>
        {clearButtonOrNull}
      </Wrapper>
    );
  }
}
