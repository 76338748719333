import { observer } from 'mobx-react';
import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import { kUIAnimationDuration } from '../lib/constants';

const FadeIn = keyframes`
  0% {
        opacity: 0;
  }
   100% {
      opacity: 1;
    }
`;

type Props = {
  text: string;
  title?: string;
  hasOptions?: boolean;
  isOn: boolean;
  buttonWasClicked?: () => void;
  menuSelectionCallback?: (menuIndex: number) => void;
};

const BaseButton = styled.button`
  animation: ${FadeIn} ${kUIAnimationDuration}s linear 1;
  border: none;
  font-size: 14px;
  text-decoration: none;
  transition: color, font-weight 0.2s linear;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #e0e0e0;
  margin-right: 25px;
`;

const OnButton = styled(BaseButton)`
  color: white;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-offset: 3px;
  text-decoration-thickness: 2px;
`;

const OffButton = styled(BaseButton)`
  cursor: pointer;
  &:hover {
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-underline-offset: 3px;
    text-decoration-thickness: 1px;
  }
  &:active {
    color: #c0c0c0;
  }
`;

export const HeaderButton = observer((props: Props) => {
  return props.isOn ? (
    <OnButton onClick={props.buttonWasClicked}>{props.text}</OnButton>
  ) : (
    <OffButton data-bold-title={props.text} onClick={props.buttonWasClicked}>
      {props.text}
    </OffButton>
  );
});
