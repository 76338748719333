export type NamedColor =
  | 'transparent'
  | 'accent'
  | 'text1'
  | 'text2'
  | 'text3'
  | 'text4'
  | 'success'
  | 'warning'
  | 'danger'
  | 'bannerColor'
  | 'bookBanner'
  | 'white'
  | '10%'
  | '20%'
  | '30%'
  | '40%'
  | '60%'
  | '80%'
  | 'black'
  | 'orange'
  | 'theme-read-our-words'
  | 'theme-te-ao-hurihuri'
  | 'theme-kia-maumahara'
  | 'theme-nga-toka-tumoana-bck'
  | 'theme-nga-toka-tumoana-cell1'
  | 'theme-nga-toka-tumoana-cell2'
  | 'theme-mana-wahine';

export class Theme {
  /*
    ———————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
    Shared instance      
    ———————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
  */

  private static instance: Theme;

  static sharedInstance(): Theme {
    if (!Theme.instance) {
      Theme.instance = new Theme();
    }
    return Theme.instance;
  }

  private static namedColors = new Map<NamedColor, string>([
    ['theme-read-our-words', `rgba(204,51,51,#alpha)`],
    ['theme-te-ao-hurihuri', `rgba(204,51,51,#alpha)`],
    ['theme-kia-maumahara', `rgba(50,150,200,#alpha)`],

    ['theme-nga-toka-tumoana-bck', `rgba(0,153,204,#alpha)`],
    ['theme-nga-toka-tumoana-cell1', `rgba(64,182,225,#alpha)`],
    ['theme-nga-toka-tumoana-cell2', `rgba(0,102,153,#alpha)`],

    ['theme-mana-wahine', `rgba(0,102,153,#alpha)`],

    ['orange', `rgba(240,120,52,#alpha)`],
    ['accent', `rgba(210,16,110,#alpha)`],
    ['success', `rgba(0,200,0,#alpha)`],
    ['text1', `rgba(20,20,20,#alpha)`],
    ['text2', `rgba(60,60,60,#alpha)`],
    ['text3', `rgba(120,120,120,#alpha)`],
    ['text4', `rgba(180,180,180,#alpha)`],
    ['warning', `rgba(180,180,0,#alpha)`],
    ['danger', `rgba(180,60,60,#alpha)`],
    ['bannerColor', 'rgba(210,16,110,#alpha)'],
    ['bookBanner', 'rgba(44,100,150,#alpha)'],
    ['white', 'rgba(255,255,255,#alpha)'],
    ['10%', 'rgba(230,230,230,#alpha)'],
    ['20%', 'rgba(200,200,200,#alpha)'],
    ['30%', 'rgba(175,175,175,#alpha)'],
    ['40%', 'rgba(150,150,150,#alpha)'],
    ['60%', 'rgba(100,100,100,#alpha)'],
    ['80%', 'rgba(50,50,50,#alpha)'],
    ['black', 'rgba(0,0,0,#alpha)'],
  ]);

  public static color(colorName: NamedColor, withAlpha: number = 1) {
    const colorString = Theme.namedColors.get(colorName);
    const alphaString = `${withAlpha}`;
    return colorString ? colorString.replace('#alpha', alphaString) : 'red';
  }

  public color(colorName: NamedColor, withAlpha: number = 1) {
    const colorString = Theme.namedColors.get(colorName);
    const alphaString = `${withAlpha}`;
    return colorString ? colorString.replace('#alpha', alphaString) : 'red';
  }
}
